import { useRef, useCallback } from 'react';
import { smoothScroll } from '../utils';

const useList = () => {
    const listRef = useRef(null);

    const scrollToItem = useCallback(({ scrollToIndex }) => {
        if (!listRef.current) return;

        if (Number.isNaN(scrollToIndex) || scrollToIndex < 0) {
            return;
        }

        const { itemSize } = listRef.current.props;
        const itemOffset = scrollToIndex * itemSize;
        const targetOffset = itemOffset - listRef.current.props.height / 2 + itemSize / 2;
        const currentOffset = listRef.current.state.scrollOffset;

        smoothScroll(currentOffset, targetOffset, 500, listRef);
    }, []);

    const scrollToSelectedItem = useCallback(
        ({ id, items }) => {
            const index = items.findIndex(item => item.id === id);
            if (index === -1) return;
            scrollToItem({ scrollToIndex: index });
        },
        [scrollToItem]
    );

    return { listRef, scrollToItem, scrollToSelectedItem };
};

export default useList;
