import React from 'react';
import PropTypes from 'prop-types';
import './TooltipMarkerData.scss';

const TooltipMarkerData = ({
    name,
    calculatedPlacedHivesAmount,
    hivesAmount,
    bhomes,
    plannedBhomesAmount,
    note,
    beeFrameAverage,
}) => (
    <div className="tooltip-marker-info">
        <h3>Drop {name}</h3>
        {/* TODO: create better solution for dentifying tooltip content */}
        {beeFrameAverage ? (
            <p>Bee frames average: {beeFrameAverage.toFixed(2)}</p>
        ) : (
            <>
                <p>
                    <span>Hives:</span>{' '}
                    {calculatedPlacedHivesAmount
                        ? `${calculatedPlacedHivesAmount}/${hivesAmount} placed`
                        : `${hivesAmount} planned`}
                </p>
                <p>
                    <span>Beehomes:</span>{' '}
                    {bhomes?.length
                        ? `${bhomes?.length}/${plannedBhomesAmount} placed`
                        : `${plannedBhomesAmount} planned`}
                </p>
                {note && <p className="tooltip-drop-info-note">&quot;{note}&quot;</p>}
            </>
        )}
    </div>
);

TooltipMarkerData.propTypes = {
    name: PropTypes.string.isRequired,
    calculatedPlacedHivesAmount: PropTypes.number,
    hivesAmount: PropTypes.number.isRequired,
    bhomes: PropTypes.arrayOf(PropTypes.number),
    plannedBhomesAmount: PropTypes.number.isRequired,
    note: PropTypes.string,
    beeFrameAverage: PropTypes.number,
};

export default TooltipMarkerData;
