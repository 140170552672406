import React, { memo, forwardRef } from 'react';
import Icon from '@beewise/icon';
import cn from 'classnames';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import Tooltip from '@beewise/tooltip';
import { getMarkerType } from './utils';
import { getBeekeeperStyles, getColorStyles } from '../utils';
import TooltipMarkerData from '../TooltipMarkerData';
import './LocationMarker.scss';

const { MARKER_HIVE_TYPES, DEFAULT_BHOME_HIVES_AMOUNT } = constants;

const ICONS = {
    [MARKER_HIVE_TYPES.BHOME]: 'beehome-inverted',
    [MARKER_HIVE_TYPES.STANDARD_HIVES]: 'wooden-hive',
};

const getLocationData = ({ placedHivesAmount, plannedHivesAmount }) => {
    if (placedHivesAmount === plannedHivesAmount) {
        return placedHivesAmount;
    } else if (!placedHivesAmount) {
        return plannedHivesAmount;
    }

    return `${placedHivesAmount} / ${plannedHivesAmount}`;
};

const SingleIcon = ({ markerType }) => <Icon type={ICONS[markerType]} color="white" />;

const MultiIcon = () => (
    <>
        <Icon type={ICONS[MARKER_HIVE_TYPES.BHOME]} color="white" />
        {' | '}
        <Icon type={ICONS[MARKER_HIVE_TYPES.STANDARD_HIVES]} color="white" />
    </>
);

const LocationMarker = forwardRef(({ onClick, className, ...marker }) => {
    const {
        bhomes = [],
        standardHives = [],
        hivesAmount = 0,
        plannedBhomesAmount = 0,
        beekeeperColor,
        name,
        note,
        beeFrameAverage,
    } = marker;

    const placedStandradHivesAmount = standardHives.reduce((total, { hivesAmount = 0 }) => total + +hivesAmount, 0);

    const totalPlacedHivesAmount = placedStandradHivesAmount + bhomes.length * DEFAULT_BHOME_HIVES_AMOUNT;

    const markerType = getMarkerType({
        placed: {
            hivesAmount: totalPlacedHivesAmount,
            bhomesHivesAmount: bhomes.length * DEFAULT_BHOME_HIVES_AMOUNT,
            standardHivesAmount: placedStandradHivesAmount,
        },
        planned: {
            hivesAmount,
            bhomesHivesAmount: plannedBhomesAmount * DEFAULT_BHOME_HIVES_AMOUNT,
            standardHivesAmount: hivesAmount - plannedBhomesAmount * DEFAULT_BHOME_HIVES_AMOUNT,
        },
    });

    const Component = markerType === MARKER_HIVE_TYPES.COMBINED ? MultiIcon : SingleIcon;
    const typeClassName = markerType === MARKER_HIVE_TYPES.COMBINED ? 'multi' : 'single';

    return (
        <Tooltip
            content={
                <TooltipMarkerData
                    name={name}
                    calculatedPlacedHivesAmount={totalPlacedHivesAmount}
                    hivesAmount={hivesAmount}
                    bhomes={bhomes}
                    plannedBhomesAmount={plannedBhomesAmount ?? 0}
                    note={note}
                    beeFrameAverage={beeFrameAverage}
                />
            }
            position="right"
        >
            <div
                // we use onMouseUp instead of onClick to avoid colision with drag events
                onMouseUp={onClick}
                className={cn(
                    'locations-marker',
                    typeClassName,
                    className,
                    getColorStyles({
                        placedHivesAmount: totalPlacedHivesAmount,
                        plannedHivesAmount: hivesAmount,
                    })
                )}
                role="presentation"
            >
                <div className="locations-marker-data">
                    {getLocationData({
                        placedHivesAmount: totalPlacedHivesAmount,
                        plannedHivesAmount: hivesAmount,
                    })}
                </div>
                <Component markerType={markerType} />
                {beekeeperColor && <div style={getBeekeeperStyles({ color: beekeeperColor })} />}
            </div>
        </Tooltip>
    );
});

LocationMarker.propTypes = {
    onClick: PropTypes.func,
    bhomes: PropTypes.arrayOf(PropTypes.number),
    standardHives: PropTypes.arrayOf(PropTypes.shape()),
    plannedBhomes: PropTypes.arrayOf(PropTypes.shape()),
    plannedStandardHives: PropTypes.arrayOf(PropTypes.shape()),
    placedHivesAmount: PropTypes.number,
    hivesAmount: PropTypes.number,
    plannedBhomesAmount: PropTypes.number,
    beekeeperColor: PropTypes.string,
    name: PropTypes.string,
    note: PropTypes.string,
    className: PropTypes.string,
    beeFrameAverage: PropTypes.number,
};

SingleIcon.propTypes = {
    markerType: PropTypes.string,
};

export default memo(LocationMarker);
