import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import LocationMarker from '.';
import './LocationMarker.scss';

const POPULATED_STRENGTH = {
    WEAK: 'weak',
    MEDIUM: 'medium',
    STRONG: 'strong',
};

const STRONG_HIVE_TRESHHOLD = 8;
const MEDIUM_HIVE_TRESHHOLD = 6;

const getFramePopulationStrength = strength => {
    if (strength >= STRONG_HIVE_TRESHHOLD) {
        return POPULATED_STRENGTH.STRONG;
    }
    if (strength >= MEDIUM_HIVE_TRESHHOLD) {
        return POPULATED_STRENGTH.MEDIUM;
    }
    return POPULATED_STRENGTH.WEAK;
};

const LocationMarkerWithFramePopulation = props => {
    const { getPopulatedFramesByLocationIdSelector } = props;
    const populatedFramesData = useSelector(state => getPopulatedFramesByLocationIdSelector(state, props.id));
    const { totalHives, totalPopulatedFrames } = populatedFramesData || {};

    if (!totalHives) {
        return <LocationMarker {...props} />;
    }

    const beeFrameAverage = totalPopulatedFrames / totalHives;
    const populationStrength = getFramePopulationStrength(beeFrameAverage);

    return (
        <div className={cn('locations-marker-wrapper', { [`${populationStrength}`]: populationStrength })}>
            <LocationMarker {...props} beeFrameAverage={beeFrameAverage} />
        </div>
    );
};

LocationMarkerWithFramePopulation.propTypes = {
    getPopulatedFramesByLocationIdSelector: PropTypes.func,
    id: PropTypes.string,
};

export default memo(LocationMarkerWithFramePopulation);
