import { useEffect, useState, useMemo } from 'react';
import { debounce } from 'lodash';

const useDebounceSearch = (delay = 500) => {
    const [searchInput, setSearchInput] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    const debouncedSetSearch = useMemo(() => debounce(query => setSearchQuery(query), delay), [delay]);
    useEffect(() => () => debouncedSetSearch.cancel(), [debouncedSetSearch]);

    const handleSearchChange = value => {
        setSearchInput(value);
        debouncedSetSearch(value);
    };

    return {
        searchInput,
        searchQuery,
        handleSearchChange,
    };
};

export default useDebounceSearch;
