import React, { memo } from 'react';
import { useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { getSelectedRanch } from 'components/views/BrokerView/selectors';
import { faNote, faLocationDot } from '@fortawesome/pro-light-svg-icons';
import { calculateTotalAmount, calculatePlacedHives } from 'components/views/BrokerView/utils';

import Icon from 'components/reusables/Icon';
import './PerformanceWidget.scss';

const WidgetItem = ({ value, title, icon, valueGetter, descriptionValueGetter }) => {
    const calculatedValue = valueGetter ? valueGetter(value) : value;
    return (
        <div className="performance-widget__item">
            <div className="row">
                <div className="performance-widget__item-name">{title}</div>
                <Icon icon={icon} className="performance-widget__item-icon" />
            </div>
            <div className="performance-widget__value_wrapper">
                <h1 className="performance-widget__item-value">{calculatedValue}</h1>
                <span className="performance-widget__item-description">
                    {descriptionValueGetter?.(calculatedValue)}
                </span>
            </div>
        </div>
    );
};

WidgetItem.propTypes = {
    value: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.shape().isRequired,
    valueGetter: PropTypes.func,
    descriptionValueGetter: PropTypes.func,
};

const PerformanceWidget = ({ control }) => {
    const selectedRanch = useSelector(getSelectedRanch, shallowEqual);

    const [locations, orders, unverifiedPlacements] = useWatch({
        control,
        name: ['locations', 'orders', 'unverifiedPlacements'],
    });

    const { requiredHivesAmount: contractedHives } = selectedRanch ?? {};
    const getDescriptionValue = value => `(${contractedHives - value} left)`;

    return (
        <section className="performance-widget">
            <WidgetItem value={contractedHives} title="Contracted" icon={faNote} />
            <WidgetItem
                value={calculateTotalAmount(locations, 'hivesAmount')}
                title="Planned"
                icon={faLocationDot}
                descriptionValueGetter={getDescriptionValue}
            />
            <WidgetItem
                value={calculateTotalAmount(orders, 'hivesAmount')}
                title="Assigned"
                icon="beekeeper"
                descriptionValueGetter={getDescriptionValue}
            />
            <WidgetItem
                value={calculatePlacedHives({ locations, unverifiedPlacements })}
                title="Placed"
                icon="place"
                descriptionValueGetter={getDescriptionValue}
            />
        </section>
    );
};

PerformanceWidget.propTypes = {
    control: PropTypes.shape({
        register: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        watch: PropTypes.func.isRequired,
    }).isRequired,
};

export default memo(PerformanceWidget);
