import React from 'react';
import MapInfoWrapper from '../../MapInfo/MapInfoWrapper';
import './HeatMapLegend.scss';

const HeatMapLegend = () => (
    <MapInfoWrapper className="heat-map-legend">
        <div className="percentage-bar" />
        <div className="percentage-marks">
            <span>100%</span>
            <span>75%</span>
            <span>50%</span>
            <span>25%</span>
            <span>0%</span>
        </div>
    </MapInfoWrapper>
);

export default HeatMapLegend;
