import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@beewise/button-v2';
import { formatDateToShortString } from 'utils/formatDateToShortString';
import './RanchHeader.scss';

const RanchHeader = ({ currentRanch }) => {
    const navigate = useNavigate();

    const navigateEditRanch = () => navigate(`/ranch/${currentRanch.id}`);

    if (!currentRanch) {
        return null;
    }

    return (
        <div className="ranch-header-container">
            <div className="ranch-header-container-block">
                <div className="ranch-header-container-block-title">{currentRanch.name}</div>
            </div>
            <div className="ranch-header-container-block">
                {currentRanch?.approvedAt && (
                    <div className="ranch-header-container-block-text">
                        {formatDateToShortString(currentRanch.approvedAt)} approved
                    </div>
                )}
                <div className="ranch-header-container-block-manage">
                    <Button color="yellow" variant="contained" onClick={navigateEditRanch}>
                        Manage Ranch
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default RanchHeader;

RanchHeader.propTypes = {
    currentRanch: PropTypes.shape(),
};
