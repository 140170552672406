import React from 'react';
import PropTypes from 'prop-types';
import useHeatMap from './useHeatMap';
import HeatMapToggle from './HeatMapToggle';
import HeatMapLegend from './HeatMapLegend';
import HeatMapTimeframes from './HeatMapTimeframes';
import HeatMapInfoButton from './HeatMapInfoButton';

const HeatMap = ({ map, zoom, data, isHeatMapShown, setIsHeatMapShown }) => {
    const { toggleHeatMapShown, timeframeIndex, setTimeframeIndex, timeframes, isFeatureAvailable } = useHeatMap({
        map,
        zoom,
        data,
        isHeatMapShown,
        setIsHeatMapShown,
    });

    if (!isFeatureAvailable) return null;
    return (
        <>
            <HeatMapToggle isHeatMapShown={isHeatMapShown} setIsHeatMapShown={toggleHeatMapShown} />
            {isHeatMapShown && (
                <>
                    <HeatMapTimeframes
                        timeframes={timeframes}
                        setTimeframeIndex={setTimeframeIndex}
                        timeframeIndex={timeframeIndex}
                    />
                    <HeatMapLegend />
                    <HeatMapInfoButton />
                </>
            )}
        </>
    );
};

HeatMap.propTypes = {
    map: PropTypes.shape(),
    zoom: PropTypes.number,
    data: PropTypes.shape(),
    isHeatMapShown: PropTypes.bool,
    setIsHeatMapShown: PropTypes.func,
};
export default HeatMap;
