import React, { useState } from 'react';
import TextField from '@beewise/text-field';
import useDebounceEffect from 'utils/hooks/useDebounceEffect';
import { useDispatch } from 'react-redux';
import { setRanchSearchQuery } from 'components/views/BrokerView/actions';
import './SearchRanches.scss';

const SearchRanches = () => {
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');

    useDebounceEffect(
        () => {
            dispatch(setRanchSearchQuery(inputValue));
        },
        [inputValue],
        500
    );

    return (
        <TextField
            type="search"
            name="Search"
            label="Search"
            className="ranches-search"
            value={inputValue}
            size="small"
            onChange={setInputValue}
        />
    );
};

export default SearchRanches;
