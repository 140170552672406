import dayjs from 'dayjs';
import constants from 'appConstants';

const { DEFAULT_BHOME_HIVES_AMOUNT } = constants;

export const getTotalDeliveredHives = ranch => {
    if (!ranch?.locations) {
        return 0;
    }

    return ranch.locations.reduce((total, location) => {
        const bhomesHives =
            location.bhomes?.reduce((sum, bhome) => sum + (bhome.hivesAmount ?? DEFAULT_BHOME_HIVES_AMOUNT), 0) ?? 0;
        const standardHives = location.standardHives?.reduce((sum, hive) => sum + hive.hivesAmount, 0) ?? 0;

        return total + bhomesHives + standardHives;
    }, 0);
};

export const getDeliveryDetails = ranch => {
    if (!ranch?.locations) {
        return [];
    }

    const deliveriesByDate = new Map();

    ranch.locations.forEach(location => {
        const allHives = [...(location.bhomes || []), ...(location.standardHives || [])];

        allHives.forEach(hive => {
            const date = dayjs(hive.deployedAt).format('MMM D, YY');

            if (!deliveriesByDate.has(date)) {
                deliveriesByDate.set(date, { bhomes: 0, standardHives: 0, totalHives: 0 });
            }

            const entry = deliveriesByDate.get(date);
            const hivesCount = hive.hivesAmount ?? DEFAULT_BHOME_HIVES_AMOUNT;

            if ('id' in hive && typeof hive.id === 'number') {
                entry.bhomes += 1;
                entry.totalHives += hivesCount;
            } else {
                entry.standardHives += hivesCount;
                entry.totalHives += hivesCount;
            }
        });
    });

    return Array.from(deliveriesByDate.entries()).sort((a, b) => dayjs(a[0]).valueOf() - dayjs(b[0]).valueOf());
};
