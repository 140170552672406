// extra labels with bars, restaurants, businesses, etc., which we don't want to show
const LABEL_CONFIG = [
    {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [{ saturation: -100 }, { lightness: 50 }],
    },
    {
        featureType: 'administrative.locality', // Controls city names
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'on', // Ensures city names are visible
            },
        ],
    },
    {
        featureType: 'administrative.locality',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'on', // Ensures any city-related icons are visible
            },
        ],
    },
];

const DEFAULT_MAP_CONFIG = {
    fullscreenControl: false,
    zoomControl: false,
    minZoom: 2,
    scaleControl: true,
    mapTypeId: 'hybrid',
    className: 'map-v2',
    restriction: {
        latLngBounds: {
            north: 85,
            south: -85,
            west: -180,
            east: 180,
        },
    },
    styles: LABEL_CONFIG,
};

export default DEFAULT_MAP_CONFIG;
