import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './MapInfo.scss';

const MapInfoWrapper = ({ children, className }) => <div className={cn('map-info', className)}>{children}</div>;

MapInfoWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default MapInfoWrapper;
