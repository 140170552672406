import dayjs from 'dayjs';
import { DEFAULT_QUALITY_OF_FRAMES } from 'components/constants';

const sortByDate = data => data.sort((a, b) => dayjs.utc(a.date).valueOf() - dayjs.utc(b.date).valueOf());

export const formatSortedHiveDevelopmentData = content => {
    if (!content?.length) {
        return [];
    }

    const sortedContent = sortByDate(content);

    return sortedContent.map(contentItem => ({
        date: dayjs(contentItem.date).format('MM/DD'),
        beeFramesPerHive: contentItem.beeFramesPerHiveAverage || 0,
        honeyFramesPerHive: contentItem.honeyFramesPerHiveAverage || 0,
        broodFramesPerHive: contentItem.broodFramesPerHiveAverage || 0,
        beeFrameStandard: contentItem.quality || DEFAULT_QUALITY_OF_FRAMES,
    }));
};
