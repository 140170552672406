import React from 'react';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import { convertArea, getUnits, getRatioHeader } from 'utils/isIsraeliRanch';
import Card from 'components/reusables/Card';
import DeliveryStatus from './DeliveryStatus';
import { getGeneralSectionFields, getPollinationDetailFields } from './utils';

import './RanchDetails.scss';

const RanchDetails = ({ ranch }) => {
    const totalArea = convertArea(ranch.totalArea, ranch?.country).toFixed();
    const units = getUnits(ranch?.country).area;
    const ratioHeader = getRatioHeader(ranch?.country);

    const lastOrder = ranch?.orders?.length ? ranch.orders[ranch.orders.length - 1] : null;

    return (
        <div className="right-panel-ranch">
            {lastOrder?.status && lastOrder.status !== constants.ORDER_STATUSES.ORDERED && (
                <DeliveryStatus order={lastOrder} />
            )}
            <Card title="General">
                {getGeneralSectionFields({
                    ranch,
                    totalArea,
                    units,
                }).map(({ name, value }) => (
                    <div key={name} className="card-item">
                        <span className="card-item-name">{name}</span>
                        <span className="card-item-value">{value}</span>
                    </div>
                ))}
            </Card>
            <Card title="Pollination Details">
                {getPollinationDetailFields({
                    ranch,
                    ratioHeader,
                    orderedHives: ranch?.totalAssignedHives,
                    placedHives: ranch?.totalPlacedHives,
                }).map(({ name, value }) => (
                    <div key={name} className="card-item">
                        <span className="card-item-name">{name}</span>
                        <span className="card-item-value">{value}</span>
                    </div>
                ))}
            </Card>
        </div>
    );
};

RanchDetails.propTypes = {
    ranch: PropTypes.shape({
        totalAssignedHives: PropTypes.number,
        totalArea: PropTypes.number,
        country: PropTypes.string,
        crop: PropTypes.string,
        note: PropTypes.string,
        trees: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                percent: PropTypes.string,
            })
        ),
        ratio: PropTypes.string,
        requiredHivesAmount: PropTypes.number,
        locations: PropTypes.arrayOf(
            PropTypes.shape({
                hivesAmount: PropTypes.number,
                placedHivesAmount: PropTypes.number,
            })
        ),
        orders: PropTypes.arrayOf(PropTypes.shape()),
        totalPlannedHives: PropTypes.number,
        totalPlacedHives: PropTypes.number,
    }),
};

export default RanchDetails;
