import { useFormContext, useFieldArray } from 'react-hook-form';

export default () => {
    const form = useFormContext();
    const {
        fields: locations,
        append: createLocation,
        remove: removeLocation,
        update: updateLocation,
    } = useFieldArray({
        control: form.control,
        name: 'locations',
        keyName: '_id',
    });

    const {
        fields: pins,
        append: createPin,
        remove: removePin,
        update: updatePin,
    } = useFieldArray({
        control: form.control,
        name: 'pins',
        keyName: '_id',
    });

    const { setValue } = form;

    const addPolygon = polygon => setValue('polygons', [...form.getValues('polygons'), polygon], { shouldDirty: true });

    const removePolygon = polygonId =>
        setValue(
            'polygons',
            form.getValues('polygons').filter(p => JSON.stringify(p) !== polygonId),
            { shouldDirty: true }
        );
    const updatePolygon = ({ id, polygon }) => {
        setValue('polygons', [...form.getValues('polygons').map(p => (JSON.stringify(p) === id ? polygon : p))], {
            shouldDirty: true,
        });
    };

    const addAccessRoad = polyline => {
        setValue('accessRoadCoordinates', [...form.getValues('accessRoadCoordinates'), polyline], {
            shouldDirty: true,
        });
    };

    const removeAccessRoad = polylineId =>
        setValue(
            'accessRoadCoordinates',
            form.getValues('accessRoadCoordinates').filter(p => JSON.stringify(p) !== polylineId),
            { shouldDirty: true }
        );

    const updateUnverifiedPlacements = data => {
        setValue('unverifiedPlacements', data, {
            shouldDirty: true,
        });
    };

    return {
        addPolygon,
        addAccessRoad,
        removeAccessRoad,
        updateUnverifiedPlacements,
        form,
        createLocation,
        removeLocation,
        updateLocation,
        locations,
        removePolygon,
        updatePolygon,
        createPin,
        removePin,
        updatePin,
        pins,
    };
};
