import constants from 'appConstants';

const { HIVE_STRENGTH } = constants;

export const calculateCurrentAverageBeeFramesPerHive = framesPerHive => {
    if (!framesPerHive.length) {
        return 0;
    }
    const sum = framesPerHive.reduce((acc, val) => acc + val, 0);
    return (sum / framesPerHive.length).toFixed(2);
};

export const calculateHivesCorrection = (framesData, ranch) => {
    if (!framesData || !Object.keys(framesData)?.length) {
        return 0;
    }
    const beeFramesAmountAgg = Object.values(framesData).flat();
    const weakFrames = beeFramesAmountAgg?.filter(beeFramesAmount => beeFramesAmount < HIVE_STRENGTH.WEAK);
    if (!weakFrames.length) {
        return 0;
    }
    const totalHivesPerBhomeDrops = ranch.totalPlacedHives - ranch.totalPlacedStandardHives;
    const weakFramesRatio = weakFrames.length / beeFramesAmountAgg.length;
    const correctedTotalAmount = (totalHivesPerBhomeDrops * weakFramesRatio).toFixed();
    const weakFramesPercent = (weakFramesRatio * 100).toFixed();
    return `${correctedTotalAmount} (${weakFramesPercent}%)`;
};
