import constants from 'appConstants';

const { MARKER_TYPES } = constants;

export const getQualityLabel = broodFramesAmount => {
    if (broodFramesAmount <= 4) {
        return 'Weak';
    }
    if (broodFramesAmount <= 8) {
        return 'Medium';
    } else return 'Strong';
};

export const calculateAverageFramesByBeehome = framesData => {
    const averageFramesByBeehome = {};

    if (!framesData) {
        return averageFramesByBeehome;
    }

    Object.entries(framesData).forEach(([beehomeId, frames]) => {
        const average = (frames.reduce((acc, val) => acc + val, 0) / frames.length).toFixed(1);
        averageFramesByBeehome[beehomeId] = { average };
    });

    return averageFramesByBeehome;
};

export const POLYGON_PARAMS = {
    capturing: true,
    strokeColor: '#fdba12',
    strokeWeight: 2,
    fillColor: 'transparent',
    fillOpacity: 0.7,
    editable: false,
    draggable: false,
    clickable: true,
    cursor: null,
};

const POLYLINE_PARAMS = {
    strokeColor: '#4392F1',
    strokeWeight: 4,
    editable: false,
};

export const createShape = ({ map, params, ShapeType }) => {
    const shape = new ShapeType(params);
    shape.setMap(map);
    return shape;
};

export const createPolyline = ({ map, coords }) => {
    const polylineCreated = createShape({
        map,
        params: { ...POLYLINE_PARAMS, path: coords },
        ShapeType: window.google.maps.Polyline,
    });
    polylineCreated.id = JSON.stringify(coords);
    return polylineCreated;
};

export const createPolygon = ({ map, polygon, fillColor = 'transparent' }) => {
    const polygonCreated = createShape({
        map,
        params: {
            ...POLYGON_PARAMS,
            paths: polygon,
            fillColor,
        },
        ShapeType: window.google.maps.Polygon,
    });
    polygonCreated.id = JSON.stringify(polygon);
    return polygonCreated;
};

const getBoundsEdges = bounds => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    return [
        // North edge
        {
            start: { lat: ne.lat(), lng: sw.lng() },
            end: { lat: ne.lat(), lng: ne.lng() },
        },
        // East edge
        {
            start: { lat: ne.lat(), lng: ne.lng() },
            end: { lat: sw.lat(), lng: ne.lng() },
        },
        // South edge
        {
            start: { lat: sw.lat(), lng: ne.lng() },
            end: { lat: sw.lat(), lng: sw.lng() },
        },
        // West edge
        {
            start: { lat: sw.lat(), lng: sw.lng() },
            end: { lat: ne.lat(), lng: sw.lng() },
        },
    ];
};

const doLineSegmentsIntersect = (line1Start, line1End, line2Start, line2End) => {
    const det =
        (line1End.lng - line1Start.lng) * (line2End.lat - line2Start.lat) -
        (line2End.lng - line2Start.lng) * (line1End.lat - line1Start.lat);

    if (det === 0) return false;

    const lambda =
        ((line2End.lat - line2Start.lat) * (line2End.lng - line1Start.lng) +
            (line2Start.lng - line2End.lng) * (line2End.lat - line1Start.lat)) /
        det;
    const gamma =
        ((line1Start.lat - line1End.lat) * (line2End.lng - line1Start.lng) +
            (line1End.lng - line1Start.lng) * (line2End.lat - line1Start.lat)) /
        det;

    return lambda > 0 && lambda < 1 && gamma > 0 && gamma < 1;
};

const isPointInPolygon = (point, polygon) => {
    let inside = false;
    // eslint-disable-next-line no-plusplus
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i].lng;
        const yi = polygon[i].lat;
        const xj = polygon[j].lng;
        const yj = polygon[j].lat;

        const intersect =
            yi > point.lat !== yj > point.lat && point.lng < ((xj - xi) * (point.lat - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
    }
    return inside;
};

const isPolygonVisible = (polygons, bounds, boundsEdges) => {
    if (!polygons.length) return false;
    let hasPointsInBounds = polygons.flat().some(point => bounds.contains(point));
    if (hasPointsInBounds) return true;

    hasPointsInBounds = polygons.some(points =>
        points.some((point, i) => {
            const start = point;
            const end = points[(i + 1) % points.length];
            return boundsEdges.some(edge => doLineSegmentsIntersect(start, end, edge.start, edge.end));
        })
    );
    if (hasPointsInBounds) return true;

    const boundsCenter = {
        lat: (bounds.getNorthEast().lat() + bounds.getSouthWest().lat()) / 2,
        lng: (bounds.getNorthEast().lng() + bounds.getSouthWest().lng()) / 2,
    };

    return polygons.some(points => isPointInPolygon(boundsCenter, points));
};

export const getVisibleMarkers = ({ markers, bounds }) => {
    if (!bounds || !markers.length) {
        return [];
    }
    const boundsEdges = getBoundsEdges(bounds);

    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    // Quick bounds check using simple comparison
    const quickBounds = {
        north: ne.lat(),
        south: sw.lat(),
        east: ne.lng(),
        west: sw.lng(),
    };

    return markers.filter(marker => {
        const { lat, lng } = marker;
        if (!lat || !lng) return false;
        // faster than bounds.contains
        const isInQuickBounds =
            lat <= quickBounds.north && lat >= quickBounds.south && lng <= quickBounds.east && lng >= quickBounds.west;
        if (isInQuickBounds) return true;
        return isPolygonVisible(marker.polygons, bounds, boundsEdges);
    });
};

export const getColorStyles = ({ placedHivesAmount, plannedHivesAmount }) => {
    if (!placedHivesAmount) {
        return MARKER_TYPES.EMPTY;
    }

    if (placedHivesAmount === plannedHivesAmount) {
        return MARKER_TYPES.PLACED;
    }

    return MARKER_TYPES.PARTIAL;
};

export const getBeekeeperStyles = ({ color }) => ({
    backgroundColor: color,
    width: 20,
    height: 5,
    marginTop: 4,
    borderRadius: 20,
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bottom: '-60%',
});
