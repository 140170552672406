module.exports = {
  ALERT_ERROR_TYPES: {
    CRITICAL_ERROR: 'CriticalError',
    ERROR: 'Error',
    WARNING: 'Warning'
  },
  ALERT_LEVELS: {
    BHOME: 'bhome',
    FRAME: 'frame',
    HIVE: 'hive'
  },
  ALERT_STATUSES: {
    READ: 'read',
    REMOVED: 'removed',
    UNREAD: 'unread'
  },
  ALERT_TYPES: {
    ACTIVITY_FAILED: 'activityFailed',
    CHALK_BROOD: 'chalk_brood',
    FOUL_BROOD: 'foul_brood',
    HONEY_LEVEL: 'honey_level',
    MULTIPLE_SPOTTY_BROOD: 'multiple_spotty_brood',
    QUEEN_CELLS: 'queen_cells',
    QUEEN_STATUS: 'queen_status',
    SPOTTY_BROOD: 'spotty_brood',
    SYRUP_LEVEL: 'syrup_level'
  },
  BHOME_MODELS: {
    bh24: '24',
    bh32: '32'
  },
  BHOME_OPERATIONAL_STATES: {
    NO_FEED: 'no feed',
    NO_SCAN: 'no scan',
    NON_OPERATIONAL: 'non operational',
    OPERATIONAL: 'operational'
  },
  BHOME_STATUSES: {
    ERROR: 'error',
    IDLE: 'idle',
    INITIALIZING: 'initializing',
    MAINTENANCE: 'maintenance',
    POWER_OUT: 'powerOut',
    RECEPTIVE: 'receptive',
    RETURNED_FROM_A_LONG_OUTAGE: 'returnedFromALongOutage'
  },
  COMMANDS: {
    ABORT_TEST: 'abortTest',
    ACQUIRE_IMAGE: 'acquireImage',
    ADD_SYRUP_TO_FEEDER: 'addSyrupToFeeder',
    ALIGN_TO: 'alignTo',
    ALIGN_TO_SLOT: 'alignToSlot',
    AUTO_CALIBRATE_ROBOT: 'autoCalibrateRobot',
    BALANCE_HIVES: 'balanceHives',
    BE_ALIGN: 'beAlign',
    BE_CHANGE_STATE: 'beChangeState',
    BE_CHECK_STATE: 'beCheckState',
    CALCULATE_FRAME_SCALE: 'calcFrameScale',
    CALIBRATE_CENTRIFUGE: 'calibrateCentrifuge',
    CALIBRATE_FEEDER: 'calibrateFeeder',
    CALIBRATE_FHTR: 'calibrateFHTR',
    CALIBRATE_FRAME_SCALE: 'calibrateFrameScale',
    CALIBRATE_NAVIGATION: 'calibrateNavigation',
    CALIBRATE_ROBOT_CENTER: 'calibrateRobotCenter',
    CALIBRATE_SCAN: 'calibrateScan',
    CALIBRATE_VISUAL_SERVOING: 'calibrateVisualServoing',
    CAMERA_LIGHT: 'cameraLight',
    CANCEL: 'cancel',
    CANCEL_PENDING_TASKS: 'cancelPendingTasks',
    CANCEL_SEQUENCES: 'cancelSequences',
    CENTERING_TEST: 'centeringTest',
    CENTRIFUGE_ROTATE: 'centrifugeRotate',
    CENTRIFUGE_ROTATE_ABS: 'centrifugeRotateAbs',
    CENTRIFUGE_ROTATE_RELATIVE: 'centrifugeRotateRelative',
    CENTRIFUGE_ROTATE_TO_SLOT: 'centrifugeRotateToSlot',
    CHANGE_ENVIRONMENT: 'changeEnvironment',
    CHANGE_FAN_STATUS: 'changeFanStatus',
    CHANGE_FANS_STATE: 'changeFansState',
    CHANGE_STATUS: 'changeStatus',
    CLOSE_HIVE: 'closeHive',
    CLOSE_SSH_TUNNEL: 'closeSshTunnel',
    COMPRESS_HIVE: 'compressHive',
    COMPRESS_HIVE_BUNDLE: 'compressHiveBundle',
    CONTRACT_HIVE: 'contractHive',
    COUNT_BEES: 'countBees',
    COUNT_BEES_FRAME: 'countBeesFrame',
    CREATE_LAYOUT: 'createLayout',
    CREATE_SSH_TUNNEL: 'createSshTunnel',
    END_SUPER_MODE: 'endSuperMode',
    END_TRANSPORTATION_MODE: 'endTransportationMode',
    END_VISIT_MODE: 'endVisitMode',
    ENGAGE_FRAME: 'engageFrame',
    EXECUTE_TEST: 'executeTest',
    EXIT_STANDBY: 'exitStandby',
    EXPAND_HIVE: 'expandHive',
    EXTRACT_FRAME: 'extractFrame',
    EXTRACT_HONEY: 'extractHoney',
    FILL_FEEDER: 'fillFeeder',
    FILL_SYRUP: 'fillSyrup',
    FRAME_LAYOUT_BUNDLE: 'frameLayoutBundle',
    FRAME_LOADER_VIDEO: 'frameLoaderVideo',
    FRAME_SCALE_CALIBRATE_FACTOR: 'frameScaleCalibrateFactor',
    FRAME_SCALE_CALIBRATE_FACTOR_NO_SEQUENCE: 'frameScaleCalibrateFactorNoSequence',
    FRAME_SCALE_CALIBRATE_ZERO_OFFSET: 'frameScaleCalibrateZeroOffset',
    FRAME_SCALE_CALIBRATE_ZERO_OFFSET_NO_SEQUENCE: 'frameScaleCalibrateZeroOffsetNoSequence',
    FRAME_SCALE_GET_CALIBRATIONS: 'frameScaleGetCalibrations',
    FRAME_SCALE_GET_WEIGHT: 'frameScaleGetWeight',
    FRAME_SCALE_SET_CALIBRATIONS: 'frameScaleSetCalibrations',
    FULL_RFID_SCAN: 'fullRfidScan',
    GET_AVAILABLE_TESTS: 'getAvailableTests',
    GET_CAMERA_DEVICES: 'getCameraDevices',
    GET_FILE_FROM_BEEHOME: 'getFileFromBeehome',
    GET_FRAME_SCALE: 'getFrameScale',
    GET_GPS_LOCATION: 'getGpsLocation',
    GET_HALLWAY_VIDEO_STATUS: 'getHallwayVideoStatus',
    GET_IR_FULL_IMAGE: 'getIrFullImage',
    GET_IR_TEMPERATURE: 'getIrTemperature',
    GET_LOGS: 'getLogs',
    GET_POWER_DATA: 'getPowerData',
    GET_ROBOT_STATUS: 'getRobotStatus',
    GET_ROBOT_VERSIONS: 'getRobotVersions',
    GET_SENSOR_VALUES: 'getSensorValues',
    GET_SYSTEM_STATUSES: 'getSystemStatuses',
    GET_SYS_INFO: 'getSysInfo',
    GET_TEST_STATUS: 'getTestStatus',
    GO_TO: 'goTo',
    GO_TO_SHIPMENT_POSITION: 'goToShipmentPosition',
    GO_TO_SLOT: 'goToSlot',
    GRIPPER_ACTION: 'gripperAction',
    HALLWAY_VIDEO: 'hallwayVideo',
    HEAT_TREATMENT_START_PROTOCOL: 'heatTreatmentStartProtocol',
    HEAT_TREATMENT_STOP_PROTOCOL: 'heatTreatmentStopProtocol',
    HEAT_TREATMENT_WAIT_TO_END: 'heatTreatmentWaitToEnd',
    HOME_CENTRIFUGE: 'homeCentrifuge',
    HOME_FRAME_LOADER: 'homeFrameLoader',
    INCUBATE: 'incubate',
    INCUBATOR_CHANGE_HEATER_STATE: 'incubatorChangeHeaterState',
    INCUBATOR_CLOSE: 'incubatorClose',
    INCUBATOR_EXTRACT: 'incubatorExtract',
    INCUBATOR_INSERT: 'incubatorInsert',
    INCUBATOR_MOVE_FRAME_TO: 'incubatorMoveFrameTo',
    INCUBATOR_OPEN: 'incubatorOpen',
    INCUBATOR_RETURN_FRAME_FROM: 'incubatorReturnFrameFrom',
    INITIALIZE_ROBOT: 'initializeRobot',
    INIT_ROBOT_PARAMS: 'initRobotParams',
    INSERT_FRAME: 'insertFrame',
    IS_FEEDER_SPILLING: 'isFeederSpilling',
    LIGHT_SWITCH: 'lightSwitch',
    LOAD_FRAME: 'loadFrame',
    LOCATE_CENTRIFUGE_IN_BH: 'locateCentrifugeInBH',
    MAKE_SPACE: 'makeSpace',
    MAP_BEEHOME: 'mapBeehome',
    MOVE_FRAME: 'moveFrame',
    MOVE_FRAMES: 'moveFrames',
    MOVE_MOTOR: 'moveMotor',
    MOVE_TO_TRANSPORTATION_POSITION: 'moveToTransportationPosition',
    MOVE_TO_VISIT_POSITION: 'moveToVisitPosition',
    NAVIGATION_CALIBRATION_BUNDLE: 'navCalibrationBundle',
    OPEN_DOOR: 'openDoor',
    OPEN_HIVE: 'openHive',
    PARTIAL_FRAME_LAYOUT: 'partialFrameLayout',
    PARTIAL_RFID_SCAN: 'partialRfidScan',
    PAUSE_ROBOT: 'pauseRobot',
    PING: 'ping',
    PLACE_FRAME: 'placeFrame',
    POKE_SLOT: 'pokeSlot',
    PRE_CALIBRATE_FEEDER: 'preCalibrateFeeder',
    PULLOUT_FRAME: 'pulloutFrame',
    PUT_DOWN_FRAME: 'putDownFrame',
    READ_QRID: 'readQrid',
    READ_RFID: 'readRfid',
    REBOOT_DEVICE: 'rebootDevice',
    RECALIBRATE_STEP: 'calibrate',
    REORDER_ACTIVITIES: 'reorderActivities',
    REPORT_GPS_DATA: 'reportGpsData',
    REPORT_HOURLY_SOLAR_DATA: 'reportHourlySolarData',
    REPORT_POWER_DATA: 'reportPowerData',
    REPORT_SOLAR_DATA: 'reportSolarData',
    RESET_LOAD_CELL_STATES: 'resetLoadCellStates',
    RESET_ROBOT: 'resetRobot',
    RESET_TEMP_CREDENTIALS: 'resetTempCredentials',
    RESUME_PENDING_TASKS: 'resumePendingTasks',
    ROBOT_GO: 'robotGo',
    RUN_CALIBRATION: 'calibrationRun',
    SCAN: 'scan',
    SCAN_FRAME: 'scanFrame',
    SET_FRAME_SCALE: 'setFrameScale',
    SET_JETSON_SYNC_STATE: 'setJetsonSyncState',
    SET_ROBOT_SPEED: 'setRobotSpeed',
    SHIFT_HIVE: 'shiftHive',
    SHAKER_LOCK: 'shakerLock',
    SHAKER_SHAKE: 'shakerShake',
    SHAKER_STOP: 'shakerStop',
    SHAKER_UNLOCK: 'shakerUnlock',
    SPILL_MAP_CALIBRATION_BUNDLE: 'spillMapCalibrationBundle',
    SPREAD_HIVE: 'spreadHive',
    START_AIR: 'startAir',
    START_FEEDER_PUMP: 'startFeederPump',
    START_RECORDING: 'startRecording',
    STOP: 'stop',
    STOP_AIR: 'stopAir',
    STOP_FEEDER_PUMP: 'stopFeederPump',
    STOP_LOG_STREAM: 'stopLogStream',
    STOP_RECORDING: 'stopRecording',
    SWARM_CONTROL: 'swarmControl',
    SYRUP_PUMP_CONTROL: 'syrupPumpControl',
    TAKE_PHOTO: 'takePhoto',
    THERMAL_SCAN_BUNDLE: 'thermalScanBundle',
    THERMAL_SCAN_FULL: 'thermalScanFull',
    THERMAL_SCAN_PARTIAL: 'thermalScanPartial',
    UNCAP_HONEYCOMB: 'uncapHoneycomb',
    UNTUCK_FRAME: 'untuckFrame',
    UPDATE_BEEHOME_MAP_VALUES: 'updateBeehomeMapValues',
    UPDATE_BHOME_STATUS: 'updateBhomeStatus',
    UPDATE_CLOUD_CONFIG: 'updateCloudConfig',
    UPDATE_CONFIG: 'updateConfig',
    UPDATE_FRAME_LAYOUT: 'updateFrameLayout',
    UPDATE_SOFTWARE: 'updateSoftware',
    UPLOAD_FRAME_LOADER_VIDEO: 'uploadFrameLoaderVideo',
    WEDGE_LOCK: 'wedgeLock',
    WEDGE_UNLOCK: 'wedgeUnlock',
    WHAT_ARE_YOU_DOING: 'whatAreYouDoing'
  },
  COMPANY_TYPES: {
    BEEKEEPER: 'beekeeper',
    GROWER: 'grower',
    INSTALLER: 'installer'
  },
  DEFAULT_BHOME_4_SLOT_WIDTH: 36,
  DEFAULT_FRAME_WIDTH: {
    COMB_FRAME: 36,
    PARTITION: 36.6,
    STATIC_PARTITION: 14
  },
  ENTRANCE_STATUSES: {
    CLOSED: 'closed',
    OPENED: 'opened'
  },
  ENTRANCES_PER_HIVE: 9,
  EVENT_NAMES: {
    ABORT_TEST: 'abortTest',
    ALERT_FROM_ROBOT: 'alertFromRobot',
    BE_CHECK_STATE: 'beCheckState',
    BEEHOME_INFO: 'beehomeInfo',
    CALCULATE_LAYOUT: 'calculateLayout',
    CANCEL_IN_PROGRESS_ACTIONS: 'cancelInProgressActions',
    CHANGE_DISABLED_HIVES: 'changeDisabledHives',
    CLOSE_SSH_TUNNEL: 'closeSshTunnel',
    CREATE_SSH_TUNNEL: 'createSshTunnel',
    DOING_ABSOLUTELY_NOTHING: 'doingAbsolutelyNothing',
    DOING_ABSOLUTELY_SOMETHING: 'doingAbsolutelySomething',
    EXECUTE_TEST: 'executeTest',
    FAIL_UPDATE_BHOME_STATUS: 'failUpdateBhomeStatus',
    FRAME_SCALE_CALIBRATE_INFO: 'frameScaleCalibrateInfo',
    FRAME_SCALE_WEIGHT: 'frameScaleWeight',
    GET_AUTO_SCAN_FRAME: 'getAutoScanFrame',
    GET_AVAILABLE_TESTS: 'getAvailableTests',
    GET_CAMERA_DEVICES: 'getCameraDevices',
    GET_CSV_REPORT: 'getCsvReport',
    GET_CONFIG: 'getConfig',
    GET_FILE_FROM_BEEHOME: 'getFileFromBeehome',
    GET_FRAME_SCALE: 'getFrameScale',
    GET_HALLWAY_VIDEO_STATUS: 'getHallwayVideoStatus',
    GET_IR_TEMPERATURE: 'getIrTemperature',
    GET_LOGS: 'getLogs',
    GET_POWER_DATA: 'getPowerData',
    GET_REVERTED_ENTRANCES: 'getRevertedEntrances',
    GET_SYSTEM_STATUSES: 'getSystemStatuses',
    GET_SYS_INFO: 'getSysInfo',
    GET_TEST_STATUS: 'getTestStatus',
    LOG_ERROR: 'logError',
    LOG_FILE: 'logFile',
    LOG_RANGE: 'logRange',
    LOG_STREAM: 'logStream',
    READ_QRID: 'readQrid',
    RECEIVE_CAMERA_DEVICES: 'receiveCameraDevices',
    RECEIVE_CALIBRATION_DATA: 'receiveCalibrationData',
    RECEIVE_LOGS_FOR_DOWNLOADING: 'receiveLogsForDownloading',
    RECEIVE_NEW_ALERT: 'receiveNewAlert',
    RECEIVE_NEW_LOGS: 'receiveNewLogs',
    RECEIVE_NEW_MESSAGE: 'receiveNewMessage',
    RECEIVE_NEW_RANGE_LOGS: 'receiveNewRangeLogs',
    RECEIVE_NEW_ROBOT_READ_RFID: 'receiveNewRobotReadRfid',
    RECEIVE_NEW_ROBOT_STATUS: 'receiveNewRobotStatus',
    RECEIVE_NEW_ROBOT_VERSIONS: 'receiveNewRobotVersions',
    RECEIVE_NEW_SUGGESTION: 'receiveNewSuggestion',
    RECEIVE_NEW_THERMAL_SCAN: 'receiveNewThermalScan',
    REMOVE_SWARM_DATA: 'removeSwarmData',
    REPORT_ERROR: 'reportError',
    REPORT_HOURLY_SOLAR_DATA: 'reportHourlySolarData',
    REPORT_POWER_DATA: 'reportPowerData',
    REPORT_SOLAR_DATA: 'reportSolarData',
    SET_LAYOUT: 'setLayout',
    SET_PARTIAL_LAYOUT: 'setPartialLayout',
    SWITCH_OFF_VIDEO_STREAM: 'switchOffVideoStream',
    TOGGLE_FRAME_LOADER_VIDEO: 'toggleFrameLoaderVideo',
    TOGGLE_HALLWAY_VIDEO: 'toggleHallwayVideo',
    TOGGLE_LIVE_VIDEO: 'toggleLiveVideo',
    UPDATE_AGGREGATION: 'updateAggregation',
    UPDATE_BHOME_INFO: 'updateBhomeInfo',
    UPDATE_BHOME_MAP: 'updateBhomeMap',
    UPDATE_BHOME_STATUS: 'updateBhomeStatus',
    UPDATE_CLOUD_CONFIG: 'updateCloudConfig',
    UPDATE_CONFIG: 'updateConfig',
    UPDATE_CONNECTED_USERS_LIST: 'updateConnectedUsersList',
    UPDATE_FEEDER_STATUS: 'updateFeederStatus',
    UPDATE_FRAME: 'updateFrame',
    UPDATE_FRAME_DATA: 'updateFrameData',
    UPDATE_FRAME_LAYOUT: 'updateFrameLayout',
    UPDATE_GPS_LOCATION: 'updateGpsLocation',
    UPDATE_HONEY_LEVEL: 'updateHoneyLevel',
    UPDATE_INTERLOCK_STATUS: 'updateInterlockStatus',
    UPDATE_JENKINS_TEST_RESULTS: 'updateJenkinsTestResults',
    UPDATE_MESSAGE_STATUS: 'updateMessageStatus',
    UPDATE_SEQUENCE_STATUS: 'updateSequenceStatus',
    UPDATE_SUGGESTION_STATUS: 'updateSuggestionStatus',
    UPDATE_SYRUP_LEVEL: 'updateSyrupLevel',
    UPDATE_SYRUP_LEVEL_SENSORS: 'updateSyrupLevelSensors'
  },
  FRAME_METRICS: {
    QUEEN_CELLS: 'queen_cell-1_to_16'
  },
  FRAME_TYPES: {
    COMB_FRAME: 'comb_frame',
    EMPTY: 'empty',
    FEEDER: 'feeder',
    FOUNDATION: 'foundation',
    PARTITION: 'partition',
    QUEEN_EXCLUDER: 'queen_excluder',
    STATIC_PARTITION: 'static_partition'
  },
  HIVE_ASSESSMENT: {
    DATA_UNAVAILABLE: 'data unavailable',
    DEAD: 'dead',
    DYING: 'dying',
    MEDIUM: 'medium',
    REPOPULATED: 'repopulated',
    STRONG: 'strong',
    WEAK: 'weak'
  },
  LOCATION_TYPES: {
    IDLE: 'idle',
    POLLINATION: 'pollination'
  },
  MARKER_TYPE: {
    EMPTY_LOCATION: 'empty-location',
    NON_POLLINATING_BHOME: 'non-pollinating-bhome',
    POLLINATING_BHOME: 'pollinating-bhome',
    STANDARD_HIVE: 'standard-hive'
  },
  MESSAGE_STATUS: {
    ACKNOWLEDGED: 'acknowledged',
    CANCELED: 'canceled',
    CANCEL_SENT: 'cancel_sent',
    ERROR: 'error',
    FAILED: 'failed',
    PENDING: 'pending',
    PROCESSING: 'processing',
    SENT: 'sent',
    SUCCESS: 'success'
  },
  MODE: {
    SUPER: 'super',
    TRANSPORTATION: 'transportation',
    VISIT: 'visit'
  },
  MQ_MESSAGE_CREATORS: {
    AUTO: 'auto',
    BCLOUD: 'bcloud',
    TECHNICIAN: 'technician',
    TESTING_APP: 'testing-app'
  },
  MQ_MESSAGE_TOPICS: {
    BHOME2CREDS: 'bhome2creds',
    LAMBDA2BHOME: 'lambda2bhome',
    SSH2BHOME: 'ssh2bhome'
  },
  OPERATING_MODES: {
    OPERATE: 'operate',
    SUGGESTING: 'suggesting'
  },
  ORDER_STATUSES: {
    CANCELED: 'canceled',
    CONFIRMED: 'confirmed',
    DELIVERED: 'delivered',
    IN_TRANSIT: 'in_transit',
    ORDERED: 'ordered',
    REMOVED: 'removed',
    SCHEDULED: 'scheduled',
    STARTED_PLACEMENT: 'started_placement'
  },
  PIN_TYPES: {
    GATE: 'gate',
    PIN: 'pin',
    ROAD_BLOCK: 'roadBlock',
    LABEL: 'label',
    LOADING_ZONE: 'loadingZone'
  },
  RANCH_APPROVAL_STATUSES: {
    APPROVED: 'approved',
    DECLINED: 'declined',
    REQUESTED: 'requested'
  },
  ROLES: {
    ADMIN: 'admin',
    BEEKEEPER: 'beekeeper',
    BROKER: 'broker',
    GROWER: 'grower',
    INSTALLER: 'installer',
    RANCH_MANAGER: 'ranch_manager',
    READ: 'read',
    SUPER_ADMIN: 'super_admin',
    SUPER_VIEWER: 'super_viewer',
    TECHNICIAN: 'technician',
    WRITE: 'write'
  },
  SLOT_STATUS: {
    DISABLED: 'disabled',
    ERROR: 'error',
    IDLE: 'idle'
  },
  SLOTS_PER_BHOME_4_STATION: 73,
  STATIONS: {
    A: 'a',
    B: 'b',
    EMPTY_STATION_1: 'empty_station_1',
    EMPTY_STATION_2: 'empty_station_2',
    EMPTY_STATION_3: 'empty_station_3',
    EMPTY_STATION_4: 'empty_station_4',
    FEEDING_DISPENSER: 'feeding_dispenser',
    HARVESTER: 'harvester',
    INCUBATOR: 'incubator',
    SYSTEM_SLOT: 'system_slot'
  },
  SUGGESTION_STATUSES: {
    ACCEPTED: 'accepted',
    EXPIRED: 'expired',
    IMPLEMENTED: 'implemented',
    INVALIDATED: 'invalidated',
    PENDING: 'pending',
    REJECTED: 'rejected'
  },
  VALID_SCAN_DAYS: 21,
  VISUAL_INSPECTION_TYPES: {
    BEE_COUNT_ONLY: 'beeCount only',
    FULL: 'full'
  }
};
