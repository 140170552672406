import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@beewise/icon-button';
import cn from 'classnames';
import Tooltip from '@beewise/tooltip';
import SearchBoxV2 from 'components/reusables/Map/components/SearchBoxV2';
import { modalController } from 'components/reusables/ModalManager/modalController';
import { MODALS } from 'components/reusables/ModalManager/config';
import { calculateUnverifiedPlacementsTotal } from 'components/views/BrokerView/utils';
import DependentField from 'components/reusables/Form/DependentField';
import { TOOLBAR_MODES } from '../utils';
import './Toolbar.scss';

const modes = Object.values(TOOLBAR_MODES);
const leftIcons = modes.slice(0, 5);
const rightIcons = modes.slice(5);

const IconsRow = ({ icons, setMode, mode, isRight }) => (
    <>
        {icons.map(({ icon, title, tooltip }) => (
            <Tooltip key={title} text={tooltip} position="top center">
                <IconButton
                    icon={icon}
                    onClick={() => setMode(title)}
                    isActive={mode === title}
                    className={cn('btn-toolbar', { right: isRight })}
                />
            </Tooltip>
        ))}
    </>
);

const BorderedButton = ({ icon, onClick, unverifiedPlacements }) => (
    <Tooltip text="Unverified placements" position="top center">
        <span className="border-button" onClick={onClick} role="presentation">
            +
            <IconButton icon={icon} />
            {unverifiedPlacements}
        </span>
    </Tooltip>
);

BorderedButton.propTypes = {
    icon: PropTypes.string,
    onClick: PropTypes.func,
    unverifiedPlacements: PropTypes.number,
};

IconsRow.propTypes = {
    icons: PropTypes.arrayOf(PropTypes.shape()),
    setMode: PropTypes.func,
    mode: PropTypes.string,
    isRight: PropTypes.bool,
};

const Toolbar = ({ mode, setMode, map, updateUnverifiedPlacements, control }) => (
    <div className="toolbar">
        <IconsRow icons={leftIcons} setMode={setMode} mode={mode} />
        <SearchBoxV2 map={map} />
        <div className="toolbar-right">
            <IconsRow icons={rightIcons} setMode={setMode} mode={mode} isRight />
            <DependentField control={control} name="unverifiedPlacements">
                {({ value: unverifiedPlacements }) => (
                    <BorderedButton
                        icon="place"
                        onClick={() =>
                            modalController.set({
                                name: MODALS.UNVERIFIED_PLACEMENT,
                                props: { updateUnverifiedPlacements, control },
                            })
                        }
                        unverifiedPlacements={calculateUnverifiedPlacementsTotal(unverifiedPlacements)}
                    />
                )}
            </DependentField>
        </div>
    </div>
);

Toolbar.propTypes = {
    mode: PropTypes.string,
    setMode: PropTypes.func,
    map: PropTypes.shape(),
    updateUnverifiedPlacements: PropTypes.func,
    control: PropTypes.shape(),
};

export default Toolbar;
