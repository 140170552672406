import React, { useState, useMemo, memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GridFooterContainer, GridPagination } from '@mui/x-data-grid';
import SearchableGrid from '@beewise/searchable-grid';
import Button, { COLORS, VARIANTS } from '@beewise/button-v2';
import { SelectField } from '@beewise/select-field';
import TextField from '@beewise/text-field';
import Menu from '@beewise/menu';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import constants from 'appConstants';
import PropTypes from 'prop-types';
import { getSelectedBrokerFilter, getBrokers } from 'components/views/BrokerView/selectors';
import useDebounceSearch from './useDebounceSearch';
import CompanyUsersPanel from '../CompanyUsersPanel';
import { getContactsData } from '../../selectors';
import { filterContacts, getIsMatchingBroker } from './utils';
import { fetchCompanyUsers, fetchRanchManagers, deleteRanchManagerById, deleteCompanyById } from '../../../../actions';

import './ContactsGrid.scss';

const ROLES = [
    { value: constants.COMPANY_TYPES.BEEKEEPER, label: 'Beekeeper' },
    { value: constants.COMPANY_TYPES.GROWER, label: 'Grower' },
    { value: constants.COMPANY_TYPES.INSTALLER, label: 'Installer' },
    { value: constants.ROLES.RANCH_MANAGER, label: 'Ranch manager' },
];

const ROLE_MAP = ROLES.reduce((acc, role) => {
    acc[role.value] = role;

    return acc;
}, {});

const RoleBadge = memo(({ role }) => {
    const roleData = ROLE_MAP[role];

    if (!roleData) {
        return <span className="role-badge unknown-role">Unknown</span>;
    }

    return <span className={`role-badge ${roleData.value}`}>{roleData.label}</span>;
});

RoleBadge.propTypes = {
    role: PropTypes.string.isRequired,
};

const MultiValueCell = memo(({ values }) => (
    <div className="cell-column">
        {values.map((item, i) => (
            <p key={`${item}${i}`}>{item?.trim()}</p>
        ))}
    </div>
));

MultiValueCell.propTypes = {
    values: PropTypes.arrayOf(PropTypes.string),
};

const getColumns = ({ handleEdit, handleDelete }) => [
    {
        field: 'companyName',
        headerName: 'Company',
        flex: 1,
        valueGetter: ({ row }) => row.name || '-',
    },
    {
        field: 'role',
        headerName: 'Role',
        flex: 0.7,
        renderCell: ({ value }) => <RoleBadge role={value} />,
        valueGetter: ({ row }) => row.type || '-',
    },
    {
        field: 'contacts',
        headerName: 'Contacts',
        flex: 1,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: ({ value }) => <MultiValueCell values={value} />,
        valueGetter: ({ row }) => (row.users?.length ? row.users.map(({ username }) => username) : ['-']),
    },
    {
        field: 'email',
        headerName: 'Email',
        flex: 1.2,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: ({ value }) => <MultiValueCell values={value} />,
        valueGetter: ({ row }) => (row.users?.length ? row.users.map(({ email }) => email) : ['-']),
    },
    {
        field: 'phone',
        headerName: 'Phone number',
        flex: 0.8,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: ({ value }) => <MultiValueCell values={value} />,
        valueGetter: ({ row }) => (row.users?.length ? row.users.map(({ phone }) => phone) : ['-']),
    },
    {
        field: 'hivesAmount',
        headerName: 'Assigned/Available Hives',
        flex: 1,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: ({ row }) => (row.data?.hivesAmount != null ? `${row.data.hivesAmount}` : '-'),
    },
    {
        field: 'brokers',
        headerName: 'Brokers',
        flex: 0.7,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: ({ value }) => <MultiValueCell values={value} />,
        valueGetter: ({ row }) => (row.brokers?.length ? row.brokers.map(({ username }) => username) : ['-']),
    },
    {
        field: 'menu',
        headerName: '',
        width: '10',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
            <Menu
                position="bottom rigth"
                options={[
                    {
                        label: 'Edit',
                        onClick: () => handleEdit(row),
                    },
                    {
                        label: 'Delete',
                        onClick: () => handleDelete(row),
                    },
                ]}
            />
        ),
    },
];

const CustomFooter = () => (
    <GridFooterContainer className="grid-footer">
        <GridPagination />
    </GridFooterContainer>
);

export const ContactsGrid = () => {
    const dispatch = useDispatch();
    const contactsData = useSelector(getContactsData);
    const brokers = useSelector(getBrokers, arrayOfObjectsShallowEqual).map(({ username, id, country }) => ({
        value: id,
        label: username,
        country,
    }));
    const selectedBrokerFilter = useSelector(getSelectedBrokerFilter);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isCompanyUsersPanelOpen, setIsCompanyUsersPanelOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { searchInput, searchQuery, handleSearchChange } = useDebounceSearch(500);

    const isMatchingBroker = getIsMatchingBroker({ selectedBrokerFilter, brokers });

    const [selectedBrokers, setSelectedBrokers] = useState(isMatchingBroker ? [selectedBrokerFilter] : []);

    const handleEdit = useCallback(row => {
        setSelectedRow(row);
        setIsCompanyUsersPanelOpen(true);
    }, []);

    const resolver = useCallback(() => {
        dispatch(fetchCompanyUsers());
        dispatch(fetchRanchManagers());
        setIsLoading(false);
    }, [dispatch]);

    const handleDelete = useCallback(
        ({ type, id }) => {
            setIsLoading(true);
            if (type === constants.ROLES.RANCH_MANAGER) {
                dispatch(deleteRanchManagerById(id, resolver));
                return;
            }

            dispatch(deleteCompanyById(id, resolver));
        },
        [dispatch, resolver]
    );

    const filteredContactsData = useMemo(
        () => filterContacts({ contactsData, selectedRoles, selectedBrokers, searchQuery }),
        [contactsData, selectedRoles, selectedBrokers, searchQuery]
    );

    const columns = useMemo(() => getColumns({ handleEdit, handleDelete }), [handleDelete, handleEdit]);

    const defaultValues = selectedRow || (isMatchingBroker ? { brokers: [{ id: selectedBrokerFilter }] } : null);

    const setIsPanelOpenHandler = () => setIsCompanyUsersPanelOpen(true);

    return (
        <>
            <div className="contacts-grid-container">
                <header className="contacts-header">
                    <h2>Contacts</h2>
                    <Button
                        color={COLORS.YELLOW}
                        variant={VARIANTS.CONTAINED}
                        onClick={setIsPanelOpenHandler}
                        size="large"
                    >
                        + Add Contact
                    </Button>
                </header>
                <div className="filters">
                    <div className="filter-selectors">
                        <SelectField
                            options={ROLES}
                            value={selectedRoles}
                            onChange={setSelectedRoles}
                            placeholder="Role"
                            isMulti
                            className="updated-select-field"
                            isCheckboxEnabled
                        />
                        <SelectField
                            options={brokers}
                            value={selectedBrokers}
                            onChange={setSelectedBrokers}
                            placeholder="Broker"
                            isMulti
                            className="updated-select-field"
                            isCheckboxEnabled
                        />
                    </div>
                    <TextField
                        name="Search"
                        label="Search"
                        value={searchInput}
                        onChange={handleSearchChange}
                        type="search"
                        size="xsmall"
                        className="contacts-search"
                    />
                </div>
                <div className="contacts-grid-table">
                    <SearchableGrid
                        columns={columns}
                        rows={filteredContactsData}
                        loading={isLoading}
                        className="searchable-grid"
                        isSearchHidden
                        pagination
                        getRowHeight={() => 'auto'}
                        sx={{
                            height: '100%',
                            width: '100%',
                            '& .MuiDataGrid-main': { fontFamily: 'Poppins', fontSize: '13px' },
                            '& .MuiDataGrid-withBorderColor': { border: 0 },
                            '& .MuiDataGrid-columnHeaderTitleContainer': {
                                padding: '15px',
                                fontWeight: '600',
                            },
                            '& .MuiDataGrid-cellContent': { padding: '16px', fontWeight: '100' },
                        }}
                        disableColumnSelector
                        disableDensitySelector
                        components={{
                            Footer: CustomFooter,
                        }}
                    />
                </div>
            </div>
            <CompanyUsersPanel
                isOpen={isCompanyUsersPanelOpen}
                onClose={() => {
                    setIsCompanyUsersPanelOpen(false);
                    setSelectedRow(null);
                }}
                roles={ROLES}
                brokers={brokers}
                defaultValues={defaultValues}
                apiActionResolver={resolver}
                setIsLoading={setIsLoading}
            />
        </>
    );
};
