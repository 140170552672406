import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { shallowEqual } from '@beewise/react-utils';
import { getFramesPerHiveByBhomeId } from 'components/views/GrowerView/selectors';
import { PieChart, Pie, Cell, Legend, Text } from 'recharts';
import useChartData from './useChartData';
import '../ReportContainer.scss';

const COLORS = ['#43976A', '#7bbb6a', '#B6CF41', '#F44336'];

const CustomTooltip = ({ active, payload }) => {
    if (active && payload?.length) {
        return (
            <div className="custom-tooltip">
                <p className="intro">{`${payload[0].payload.hives} Hives`}</p>
            </div>
        );
    }

    return null;
};

CustomTooltip.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.arrayOf(PropTypes.shape()),
};

const CustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
    let x = cx + radius * Math.cos((-midAngle * Math.PI) / 180);
    const y = cy + radius * Math.sin((-midAngle * Math.PI) / 180);

    if (x < cx) {
        x += 10;
    }

    if (percent > 0) {
        return (
            <Text x={x} y={y} fill="#FFFFFF" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${percent}%`}
            </Text>
        );
    }

    return null;
};

CustomLabel.propTypes = {
    cx: PropTypes.number,
    cy: PropTypes.number,
    midAngle: PropTypes.number,
    innerRadius: PropTypes.number,
    outerRadius: PropTypes.number,
    percent: PropTypes.number,
};

const CustomLegend = () => (
    <div className="custom-legend">
        <ul className="custom-legend-list">
            <li className="text">
                <span className="legend-icon green-icon" /> {'>'}10 Frames
            </li>
            <li className="text">
                <span className="legend-icon primary-green-icon" /> 8-10 Frames
            </li>
            <li className="text">
                <span className="legend-icon light-green-icon" /> 6-8 Frames
            </li>
            <li className="text">
                <span className="legend-icon red-icon" /> {'<'}6 Frames
            </li>
        </ul>
    </div>
);

const FramesPerHive = ({ ranchId, isHardcodedData }) => {
    const framesPerHiveByBhomeId = useSelector(getFramesPerHiveByBhomeId, shallowEqual);
    const chartData = useChartData(framesPerHiveByBhomeId, isHardcodedData, ranchId);

    if (!chartData) {
        return null;
    }

    return (
        <div className="report-container">
            <h3 className="header">Frames per Hive</h3>
            <PieChart width={400} height={244}>
                <Pie
                    data={chartData}
                    cx="50%"
                    cy="50%"
                    innerRadius={50}
                    outerRadius={90}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="percent"
                    labelLine={false}
                    label={CustomLabel}
                >
                    {chartData.map((entry, index) => (
                        <Cell key={entry.name} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                {/* <Tooltip content={<CustomTooltip />} /> */}
                <Legend align="left" verticalAlign="middle" layout="vertical" content={CustomLegend} />
            </PieChart>
        </div>
    );
};

FramesPerHive.propTypes = {
    ranchId: PropTypes.string.isRequired,
    isHardcodedData: PropTypes.bool,
};

export default memo(FramesPerHive);
