import React from 'react';
import { useSelector } from 'react-redux';
import { shallowEqual, arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import { getSelectedRanch, getFilteredRanches, getRanches } from 'components/views/BrokerView/selectors';
import useRanchSelection from 'components/views/BrokerView/views/Dashboard/hooks/useRanchSelection';
import RanchHeader from './RanchHeader';
import RanchDetails from './RanchDetails';
import MapContainer from './MapContainer';
import './RanchInfo.scss';

const RanchInfo = () => {
    const currentRanch = useSelector(getSelectedRanch, shallowEqual);
    const filteredRanches = useSelector(getFilteredRanches, arrayOfObjectsShallowEqual);
    // TODO: do not use whole ranch array to detect initial ranch list changes, think about better approach
    const ranches = useSelector(getRanches, arrayOfObjectsShallowEqual);

    const { handleSelectRanch } = useRanchSelection();

    return (
        <div className="ranch-container">
            <RanchHeader currentRanch={currentRanch} />
            <MapContainer
                currentRanch={currentRanch}
                handleSelectRanch={handleSelectRanch}
                filteredRanches={filteredRanches}
                ranches={ranches}
            />
            <RanchDetails currentRanch={currentRanch} />
        </div>
    );
};

export default RanchInfo;
