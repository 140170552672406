import React from 'react';
import MapInfoWrapper from './MapInfoWrapper';
import './MapInfo.scss';

const MapInfo = () => (
    <MapInfoWrapper>
        <div className="map-info-block">
            <span className="map-info-block-span empty" /> Unplaced
        </div>
        <div className="map-info-block">
            <span className="map-info-block-span partial" /> Partially placed
        </div>
        <div className="map-info-block">
            <span className="map-info-block-span placed" /> Fully placed
        </div>
    </MapInfoWrapper>
);

export default MapInfo;
