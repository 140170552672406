import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useFetch from 'utils/hooks/useFetch';
import { fetchHeatMap, FETCH_HEAT_MAP } from './actions';

const useHeatMapData = ({ ranchId }) => {
    const [isHeatMapShown, setIsHeatMapShown] = useState(true);
    const params = useMemo(() => (ranchId ? { ranchId } : null), [ranchId]);
    const { data } = useFetch({ action: fetchHeatMap, params });
    const isLoading = useSelector(state => state.ui.loading[FETCH_HEAT_MAP.default]);
    if (!data?.points?.length || isLoading) {
        return { data: null, isHeatMapShown: false };
    }
    return { data, isHeatMapShown, setIsHeatMapShown };
};

export default useHeatMapData;
