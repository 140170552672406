import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import MapInfoWrapper from '../../MapInfo/MapInfoWrapper';
import './HeatMapTimeframes.scss';

const formatTimeframe = timeframe => dayjs(timeframe, 'DD/MM/YYYY').format('MMM DD');

const isBetween = ({ value, start, end }) => value >= start && value <= end;

const TIMEFRAMES_TO_SHOW = 4;
const VISIBLE_TIMEFRAMES_INDEX_OFFSET = TIMEFRAMES_TO_SHOW - 1;

const NavigationArrow = ({ onClick, icon, isShown }) => {
    if (!isShown) return null;
    return <FontAwesomeIcon icon={icon} onClick={onClick} />;
};

NavigationArrow.propTypes = {
    onClick: PropTypes.func,
    icon: PropTypes.shape(),
    isShown: PropTypes.bool,
};

const TimeframeButton = ({ timeframe, index, timeframeIndex, setTimeframeIndex, isShown }) => {
    if (!isShown) return null;
    return (
        <button onClick={() => setTimeframeIndex(index)} className={index === timeframeIndex ? 'selected' : ''}>
            {formatTimeframe(timeframe)}
        </button>
    );
};

TimeframeButton.propTypes = {
    timeframe: PropTypes.string,
    index: PropTypes.number,
    timeframeIndex: PropTypes.number,
    setTimeframeIndex: PropTypes.func,
    isShown: PropTypes.bool,
};

const FIRST_ITEM_INDEX = 0;

const startIndexDisplayedInitial = timeframeIndex =>
    Math.max(timeframeIndex - VISIBLE_TIMEFRAMES_INDEX_OFFSET, FIRST_ITEM_INDEX);

const HeatMapTimeframes = ({ timeframes, setTimeframeIndex, timeframeIndex }) => {
    const [startIndexDisplayed, setStartIndexDisplayed] = useState(startIndexDisplayedInitial(timeframeIndex));
    const lastIndexDisplayed = startIndexDisplayed + VISIBLE_TIMEFRAMES_INDEX_OFFSET;
    const handleNextClick = value => () => {
        setStartIndexDisplayed(startIndexDisplayed + value);
        setTimeframeIndex(timeframeIndex + value);
    };
    return (
        <MapInfoWrapper className="heat-map-timeframes">
            <NavigationArrow onClick={handleNextClick(-1)} icon={faChevronLeft} isShown={!!startIndexDisplayed} />
            {timeframes?.map((timeframe, index) => (
                <TimeframeButton
                    key={`${timeframe}-${index}`}
                    isShown={isBetween({ value: index, start: startIndexDisplayed, end: lastIndexDisplayed })}
                    timeframe={timeframe}
                    index={index}
                    timeframeIndex={timeframeIndex}
                    setTimeframeIndex={setTimeframeIndex}
                />
            ))}
            <NavigationArrow
                onClick={handleNextClick(1)}
                icon={faChevronRight}
                isShown={lastIndexDisplayed < timeframes.length - 1}
            />
        </MapInfoWrapper>
    );
};

HeatMapTimeframes.propTypes = {
    timeframes: PropTypes.arrayOf(PropTypes.string),
    setTimeframeIndex: PropTypes.func,
    timeframeIndex: PropTypes.number,
};

export default HeatMapTimeframes;
