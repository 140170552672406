import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateMapBounds } from 'components/reusables/Map/components/Map/utils';
import { createPolyline, createPolygon } from 'components/reusables/Map/components/utils';
import { getSelectedRanch } from 'components/views/BrokerView/selectors';

const useCreateInitialFigures = ({ map, polygonsRef, accessRoadRef }) => {
    const { id: isEditMode } = useParams();

    const selectedRanch = useSelector(getSelectedRanch, shallowEqual);

    useEffect(() => {
        if (!isEditMode || !selectedRanch || !map || polygonsRef.current.length) {
            return;
        }

        const { polygons } = selectedRanch;
        const points = polygons.flat();
        updateMapBounds(map, points);
        polygons.forEach(polygon => {
            const polygonCreated = createPolygon({ map, polygon });
            polygonsRef.current.push(polygonCreated);
        });

        if (selectedRanch.accessRoadCoordinates) {
            selectedRanch.accessRoadCoordinates.forEach(accessRoad => {
                const coords = accessRoad.map(coord => ({
                    lat: coord.lat,
                    lng: coord.lng,
                }));
                accessRoadRef.current.push(createPolyline({ map, coords }));
            });
        }
    }, [accessRoadRef, isEditMode, map, selectedRanch, polygonsRef]);
};

export default useCreateInitialFigures;
