import globalConstants from '@beewise/constants';

export default {
    BHOME_ACRE_COVERAGE: 12,
    DEFAULT_HIVES_AMOUNT: 12,
    DEFAULT_BHOME_HIVES_AMOUNT: 8,
    GRAPH_DATA: {
        avocado: {
            KEY: 'numCells',
        },
        almonds: {
            KEY: 'numCells',
        },
    },
    MAP_MODES: {
        POLYGON: 'polygon',
        ERASE: 'erase',
        EDIT: 'edit',
    },
    MARKER_MODE: {
        ADD: 'add',
        REMOVE: 'remove',
        EDIT: 'edit',
    },
    TOOLBOX_MODE: {
        REMOVE_POLYGON: 'remove_polygon',
        MOVE: 'edit_location',
        AUTOPLACE: 'autoplace',
        PLACE_LOCATION: 'place_location',
        REMOVE_ALL_LOCATIONS: 'remove_all_locations',
        MARK_LOADING_ZONE: 'mark_loading_zone',
        MARK_ACCESS_ROAD: 'mark_access_road',
        MARK_PINS: 'mark_note',
        MARK_GATES: 'mark_gates',
        MARK_ROAD_BLOCKS: 'mark_road_blocks',
    },
    INVITED_ID: 'invited',
    UNKNOWN_BEEKEEPER_ID: 'later',
    NEW_LOCATION_PREFIX: 'new-location-id:',
    NEW_PIN_PREFIX: 'new-pin-id:',
    NEW_BLOCK_PREFIX: 'block_',
    FULL_STORY_ORG_ID: 'o-1GYWVT-na1',
    CROP: {
        almonds: 'Almonds',
        avocado: 'Avocado',
        blueberry: 'Blueberry',
        kiwi: 'Kiwi',
        cilantro_seeds: 'Cilantro Seeds',
        sunflower: 'Sunflower',
        alfalfa: 'Alfalfa',
    },
    COMPANY_TYPES: {
        GROWER: 'grower',
        BEEKEEPER: 'beekeeper',
        INSTALLER: 'installer',
    },
    DEFAULT_CURSOR_URL: 'https://maps.gstatic.com/mapfiles/openhand_8_8.cur',
    MAP: {
        API_KEY: 'AIzaSyD9CIM7V96Rpbmi1aWqJrAMP-wn5C_ntdY',
        DEFAULT_CENTER: { lat: 36.77, lng: -119.41 },
        DEFAULT_CURSOR_URL: 'https://maps.gstatic.com/mapfiles/openhand_8_8.cur',
        ZOOM: {
            MIDDLE: 12,
            LARGE: 14,
        },
    },
    REPORTS: {
        CORRECTION_FACTOR: 1.2,
    },
    MARKER_TYPES: {
        EMPTY: 'empty',
        PLACED: 'placed',
        PARTIAL: 'partial',
    },
    MARKER_HIVE_TYPES: {
        BHOME: 'bhome',
        STANDARD_HIVES: 'standardHives',
        COMBINED: 'combined',
    },
    HIVE_STRENGTH: {
        STRONG: 10,
        MIDDLE: 8,
        WEAK: 6,
    },
    ...globalConstants,
};
