import React, { memo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { SelectField } from '@beewise/select-field';
import Button from '@beewise/button';
import { getBeeAverage, getHiveCorrectionPercent, getHardcodedCorrection } from 'data';
import { shallowEqual } from '@beewise/react-utils';
import RanchInfo from '../Ranchlnfo';
import SeasonProgressMeter from '../reports/SeasonProgressMeter';
import BeeActivity from '../reports/BeeActivity';
import FramesPerHive from '../reports/FramesPerHive';
import './GeneralInfoWithReports.scss';
import NumberOfSavedBees from '../reports/NumberOfSavedBees';
// import HiveDevelopment from '../reports/HiveDevelopment';
import { getFramesPerHiveByBhomeId } from '../../selectors';
import { calculateCurrentAverageBeeFramesPerHive, calculateHivesCorrection } from './utils';

const getPollinationSeasonDates = selectedRanch => {
    const earliestDeploymentDate =
        selectedRanch?.locations?.reduce((earliest, location) => {
            const locationEarliest = location?.bhomes?.reduce((acc, bhome) => {
                const deployedAt = bhome?.deployedAt ? dayjs(bhome.deployedAt) : null;

                return deployedAt && (!acc || deployedAt.isBefore(acc)) ? deployedAt : acc;
            }, null);

            return locationEarliest && (!earliest || locationEarliest.isBefore(earliest)) ? locationEarliest : earliest;
        }, null) || dayjs();

    const startDate = earliestDeploymentDate.format('YYYY-MM-DD');

    const endDate = '2025-03-15';

    const validStartDate = dayjs(startDate).isAfter(endDate) ? endDate : startDate;

    const isEndDateValid = dayjs(endDate).isBefore(dayjs()) && dayjs(endDate).isBefore(validStartDate);

    return {
        startDate: validStartDate,
        endDate,
        lastDate: isEndDateValid ? endDate : dayjs().format('YYYY-MM-DD'),
    };
};

const GeneralInfoWithReports = ({ ranches = [], selectedRanch, exportImage, handleSelectRanch }) => {
    const isHardcodedData = selectedRanch?.showPotential;
    const ranchOptions = ranches?.map(ranch => ({
        label: ranch?.name,
        value: ranch?.id,
    }));

    const framesPerHiveByBhomeId = useSelector(getFramesPerHiveByBhomeId, shallowEqual);

    const currentAverageBeeFramesPerHive = isHardcodedData
        ? getBeeAverage(selectedRanch.id)
        : calculateCurrentAverageBeeFramesPerHive(framesPerHiveByBhomeId);

    const correction = isHardcodedData
        ? getHardcodedCorrection({
              totalBhomesHives: selectedRanch?.totalPlannedHives,
              hiveCorrectionPercent: getHiveCorrectionPercent(selectedRanch.id),
          })
        : calculateHivesCorrection(framesPerHiveByBhomeId, selectedRanch);

    const { startDate, endDate, lastDate } = getPollinationSeasonDates(selectedRanch);

    const onRanchChange = ranchId => handleSelectRanch({ ranchId });

    return (
        !!ranches?.length && (
            <div className="general-info-with-reports">
                <div className="ranch-header">
                    <div className="nav-section">
                        {ranchOptions.length === 1 && selectedRanch ? (
                            <h3 className="ranch-header-text">{selectedRanch.name}</h3>
                        ) : (
                            <SelectField
                                className="search-select-field"
                                name="ranch"
                                options={ranchOptions}
                                value={selectedRanch?.id}
                                onChange={onRanchChange}
                            />
                        )}
                        <Button
                            shadow
                            onClick={exportImage}
                            className="primary-blue remove-from-jpeg"
                            iconType="arrow-down-with-line-on-top"
                        >
                            download
                        </Button>
                    </div>
                    {!!selectedRanch?.id && !!selectedRanch?.totalBhomes && (
                        <NumberOfSavedBees
                            startDate={startDate}
                            ranch={selectedRanch}
                            bhomesAmount={selectedRanch?.totalBhomes}
                        />
                    )}
                </div>
                <RanchInfo
                    ranch={selectedRanch}
                    bhomesAmount={selectedRanch?.totalBhomes}
                    startDate={startDate}
                    endDate={endDate}
                />
                {!!selectedRanch?.id && !!selectedRanch?.totalBhomes && (
                    <>
                        <div className="ranch-reports">
                            <div className="report-container widget-container">
                                <h3 className="header">Average bee frames per hive</h3>
                                <h3 className="header">{currentAverageBeeFramesPerHive}</h3>
                            </div>
                            <div className="report-container widget-container">
                                <h3 className="header">Hives being corrected</h3>
                                <h3 className="header">{correction}</h3>
                            </div>
                        </div>
                        <div className="ranch-reports">
                            <SeasonProgressMeter startDate={startDate} endDate={endDate} />
                            <FramesPerHive ranchId={selectedRanch.id} isHardcodedData={isHardcodedData} />
                        </div>
                        <BeeActivity
                            isHardcodedData={isHardcodedData}
                            ranchId={selectedRanch.id}
                            startDate={startDate}
                            endDate={dayjs(lastDate).subtract(1, 'day').format('YYYY-MM-DD')}
                        />
                        {/* <HiveDevelopment
                            isHardcodedData={isHardcodedData}
                            ranch={selectedRanch}
                            startDate={startDate}
                            endDate={dayjs(lastDate).format('YYYY-MM-DD')}
                        /> */}
                    </>
                )}
            </div>
        )
    );
};

export default memo(GeneralInfoWithReports);

GeneralInfoWithReports.propTypes = {
    ranches: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
        })
    ),
    selectedRanch: PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.string,
        locations: PropTypes.arrayOf(PropTypes.shape()),
        crop: PropTypes.string,
        country: PropTypes.string,
        showPotential: PropTypes.bool,
        totalBhomes: PropTypes.number,
        totalPlannedHives: PropTypes.number,
    }),
    handleSelectRanch: PropTypes.func.isRequired,
    exportImage: PropTypes.func.isRequired,
};
