import React, { useLayoutEffect } from 'react';
import { loading } from '@beewise/react-utils';
import { useSelector } from 'react-redux';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import ContentLoader from 'react-content-loader';
import { getSelectedRanchId } from 'components/views/BrokerView/selectors';
import PropTypes from 'prop-types';
import { useListContext } from 'components/views/BrokerView/views/Dashboard/context/ListContext';
import useRanchSelection from 'components/views/BrokerView/views/Dashboard/hooks/useRanchSelection';
import RanchItem from './RanchItem';
import { FETCH_RANCHES } from '../../../../../actionTypes';
import './RanchesList.scss';

const TOP_HEIGHT = 304;
const ITEM_SIZE = 88;
const DUMMY_RANCH_COUNT = 11;

const RanchListContentLoader = () => (
    <div className="ranches-list">
        <ContentLoader height="100%" width="100%" speed={1} backgroundColor="#EEEEEE" foregroundColor="#BDBDBD">
            {[...Array(DUMMY_RANCH_COUNT)].map((_, index) => {
                const uniqueKey = `ranch-loader-${index}`;
                return (
                    <React.Fragment key={uniqueKey}>
                        <rect x="36" y={index * 60 + 10} rx="3" ry="3" width="20%" height="20" />
                        <rect x="calc(100% - 36px - 60%)" y={index * 60 + 10} rx="3" ry="3" width="60%" height="20" />
                    </React.Fragment>
                );
            })}
        </ContentLoader>
    </div>
);

const ListData = ({ height, width, ranches, filteredRanches }) => {
    const selectedRanchId = useSelector(getSelectedRanchId);
    const { listRef, scrollToSelectedItem } = useListContext();
    const { handleSelectRanch } = useRanchSelection();
    useLayoutEffect(() => {
        if (!ranches.length || !selectedRanchId) {
            return;
        }
        scrollToSelectedItem({ id: selectedRanchId, items: ranches });
        // sroll only on first render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ranches, scrollToSelectedItem]);

    return (
        <List
            className="ranches-list"
            itemCount={filteredRanches.length}
            itemSize={ITEM_SIZE}
            height={height - TOP_HEIGHT}
            width={width}
            itemData={{ filteredRanches, handleSelectRanch }}
            ref={listRef}
        >
            {RanchItem}
        </List>
    );
};

const RanchListComponent = ({ ranches, filteredRanches }) => (
    <AutoSizer>
        {({ height, width }) => (
            <ListData height={height} width={width} ranches={ranches} filteredRanches={filteredRanches} />
        )}
    </AutoSizer>
);

const RanchesList = loading(FETCH_RANCHES.default, RanchListContentLoader)(RanchListComponent);

export default RanchesList;

RanchListComponent.propTypes = {
    ranches: PropTypes.arrayOf(PropTypes.shape()),
    filteredRanches: PropTypes.arrayOf(PropTypes.shape()),
};

ListData.propTypes = {
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    ranches: PropTypes.arrayOf(PropTypes.shape()),
    filteredRanches: PropTypes.arrayOf(PropTypes.shape()),
};
