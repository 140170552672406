import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { InputFormField, SelectFormField } from '@beewise/hook-form';
import Button, { COLORS, VARIANTS } from '@beewise/button-v2';
import constants from 'appConstants';
import useDebounceEffect from 'utils/hooks/useDebounceEffect';
import UserFields from './components/index';
import {
    createCompanyUsers,
    fetchCompanyByName,
    createRanchManager,
    updateCompanyUsers,
    updateRanchManager,
} from '../../../../actions';
import { getDefaultValues, getRanchManagerPayload, getCompanyUsersPayload } from './utils';

import AsidePanel from '../AsidePanel';

import './CompanyUsersPanel.scss';

const CompanyUsersPanel = ({ isOpen, onClose, roles, brokers, defaultValues, apiActionResolver, setIsLoading }) => {
    const dispatch = useDispatch();

    const isEditMode = !!defaultValues?.type;

    const form = useForm({
        defaultValues: getDefaultValues(defaultValues),
    });

    const {
        control,
        handleSubmit,
        watch,
        setError,
        clearErrors,
        formState: { errors },
        reset,
    } = form;

    const {
        fields: userFields,
        append: appendUser,
        remove: removeUser,
    } = useFieldArray({
        control,
        name: 'users',
    });

    const [companyName, companyType] = watch(['companyName', 'companyType']);

    useEffect(() => {
        reset(getDefaultValues(defaultValues));
    }, [defaultValues, reset]);

    useDebounceEffect(
        () => {
            if (!companyName || typeof companyName !== 'string' || isEditMode) {
                return;
            }

            dispatch(
                fetchCompanyByName(companyName, exists => {
                    if (exists) {
                        setError('companyName', {
                            type: 'manual',
                            message: 'Company name already exists',
                        });
                    } else {
                        clearErrors('companyName');
                    }
                })
            );
        },
        [companyName],
        500
    );

    const handleClose = () => {
        clearErrors();
        reset(getDefaultValues());
        onClose();
    };

    const onSubmit = data => {
        if (errors.companyName) {
            return;
        }

        if (data?.users?.length) {
            data.users = data.users.filter(user => user.username || user.email || user.phone);
        } else {
            data.users = [];
        }

        let action;
        let payload;

        if (data.companyType === constants.ROLES.RANCH_MANAGER) {
            payload = getRanchManagerPayload({ data });
            action = isEditMode ? updateRanchManager : createRanchManager;
        } else {
            payload = getCompanyUsersPayload({ data, isEditMode, brokers });
            action = isEditMode ? updateCompanyUsers : createCompanyUsers;
        }

        setIsLoading(true);
        if (isEditMode) {
            dispatch(action(data.companyId, payload, apiActionResolver));
        } else {
            dispatch(action(payload, apiActionResolver));
        }

        handleClose();
    };

    return (
        <AsidePanel
            isPanelOpen={isOpen}
            isWithOverlay
            side="left"
            onClose={handleClose}
            className="company-users-panel"
        >
            <div className="company-users-panel-content">
                <form className="company-users-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="header-row">
                        <h2>{isEditMode ? 'Edit Contact' : 'Add Contact'}</h2>
                        <button type="button" className="close-button" onClick={handleClose}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                    <div className="form-wrapper">
                        <div className="form-section">
                            <SelectFormField
                                name="brokerIds"
                                control={control}
                                label="Broker"
                                required
                                options={brokers}
                                isMulti
                                className="form-field updated-select-field"
                                isCheckboxEnabled
                            />
                            <SelectFormField
                                name="companyType"
                                control={control}
                                label="Company Type"
                                required
                                options={roles}
                                className="form-field"
                                disabled={isEditMode}
                            />
                            {companyType !== constants.ROLES.RANCH_MANAGER && (
                                <InputFormField
                                    name="companyName"
                                    control={control}
                                    label="Company Name"
                                    required
                                    className="form-field"
                                    error={
                                        errors.companyName && (errors.companyName.message || 'This field is required')
                                    }
                                />
                            )}
                            {companyType === constants.COMPANY_TYPES.BEEKEEPER && (
                                <InputFormField
                                    name="hivesAmount"
                                    type="number"
                                    min={0}
                                    control={control}
                                    label="Available Hives"
                                    className="form-field"
                                />
                            )}
                        </div>
                        <div className="users-section">
                            {userFields.map((item, index) => (
                                <UserFields
                                    key={item.id}
                                    item={item}
                                    index={index}
                                    control={control}
                                    removeUser={removeUser}
                                    companyType={companyType}
                                    userFields={userFields}
                                    appendUser={appendUser}
                                    clearErrors={clearErrors}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="footer-row">
                        <Button color={COLORS.GRAY} variant={VARIANTS.TEXT} onClick={handleClose} type="button">
                            Cancel
                        </Button>
                        <Button color={COLORS.YELLOW} variant={VARIANTS.CONTAINED} type="submit">
                            {isEditMode ? 'Update' : 'Save'}
                        </Button>
                    </div>
                </form>
            </div>
        </AsidePanel>
    );
};

CompanyUsersPanel.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    roles: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    brokers: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    defaultValues: PropTypes.shape({
        id: PropTypes.string,
        brokers: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
            })
        ),
        name: PropTypes.string,
        type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        data: PropTypes.shape({
            hivesAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        users: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                phone: PropTypes.string,
                username: PropTypes.string,
                email: PropTypes.string,
                role: PropTypes.string,
            })
        ),
    }),
    apiActionResolver: PropTypes.func.isRequired,
    setIsLoading: PropTypes.func.isRequired,
};

export default CompanyUsersPanel;
