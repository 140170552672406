import { useMemo } from 'react';
import constants from 'appConstants';
import { getCategories as getHardcodedCategories } from 'data';

const { HIVE_STRENGTH } = constants;

const getInitialCategories = () => ({
    '>10 Frames': { hives: 0, percent: 0 },
    '8-10 Frames': { hives: 0, percent: 0 },
    '6-8 Frames': { hives: 0, percent: 0 },
    '<6 Frames': { hives: 0, percent: 0 },
});

const useChartData = (dataModel, isHardcodedData, ranchId) =>
    useMemo(() => {
        if (isHardcodedData) {
            return getHardcodedCategories(ranchId);
        }
        if (!dataModel || !Object.keys(dataModel)?.length) {
            return;
        }
        const categories = getInitialCategories();

        const totalHives = dataModel.length;
        dataModel.forEach(beeFrames => {
            let categoryKey;
            if (beeFrames > HIVE_STRENGTH.STRONG) {
                categoryKey = '>10 Frames';
            } else if (beeFrames > HIVE_STRENGTH.MIDDLE) {
                categoryKey = '8-10 Frames';
            } else if (beeFrames >= HIVE_STRENGTH.WEAK) {
                categoryKey = '6-8 Frames';
            } else {
                categoryKey = '<6 Frames';
            }
            categories[categoryKey].hives += 1;
        });

        if (totalHives > 0) {
            Object.keys(categories).forEach(category => {
                categories[category].percent = (categories[category].hives / totalHives) * 100;
            });
        }

        const chartData = Object.keys(categories).map(category => ({
            name: category,
            percent: +categories[category].percent.toFixed(),
            hives: categories[category].hives,
        }));

        return chartData;
    }, [isHardcodedData, dataModel, ranchId]);

export default useChartData;
