export const data = [
    {
        date: '2024-01-29T00:00:00.000-08:00',
        pollinationPotential: 91.41,
        beeFramesPerHive: 11.7,
    },
    {
        date: '2024-01-30T00:00:00.000-08:00',
        pollinationPotential: 89.58,
        beeFramesPerHive: 11.7,
    },
    {
        date: '2024-01-31T00:00:00.000-08:00',
        pollinationPotential: 3.66,
        beeFramesPerHive: 11.7,
    },
    {
        date: '2024-02-01T00:00:00.000-08:00',
        pollinationPotential: 32.91,
        beeFramesPerHive: 11.7,
    },
    {
        date: '2024-02-02T00:00:00.000-08:00',
        pollinationPotential: 12.8,
        beeFramesPerHive: 11.7,
    },
    {
        date: '2024-02-03T00:00:00.000-08:00',
        pollinationPotential: 7.31,
        beeFramesPerHive: 11.7,
    },
    {
        date: '2024-02-04T00:00:00.000-08:00',
        pollinationPotential: 10.97,
        beeFramesPerHive: 11.7,
    },
    {
        date: '2024-02-05T00:00:00.000-08:00',
        pollinationPotential: 9.06,
        beeFramesPerHive: 11.6,
    },
    {
        date: '2024-02-06T00:00:00.000-08:00',
        pollinationPotential: 10.88,
        beeFramesPerHive: 11.6,
    },
    {
        date: '2024-02-07T00:00:00.000-08:00',
        pollinationPotential: 1.81,
        beeFramesPerHive: 11.6,
    },
    {
        date: '2024-02-08T00:00:00.000-08:00',
        pollinationPotential: 3.63,
        beeFramesPerHive: 11.6,
    },
    {
        date: '2024-02-09T00:00:00.000-08:00',
        pollinationPotential: 19.77,
        beeFramesPerHive: 11.5,
    },
    {
        date: '2024-02-10T00:00:00.000-08:00',
        pollinationPotential: 27.19,
        beeFramesPerHive: 11.6,
    },
    {
        date: '2024-02-11T00:00:00.000-08:00',
        pollinationPotential: 62.16,
        beeFramesPerHive: 11.7,
    },
    {
        date: '2024-02-12T00:00:00.000-08:00',
        pollinationPotential: 58.13,
        beeFramesPerHive: 12,
    },
    {
        date: '2024-02-13T00:00:00.000-08:00',
        pollinationPotential: 54.38,
        beeFramesPerHive: 12,
    },
    {
        date: '2024-02-14T00:00:00.000-08:00',
        pollinationPotential: 35.63,
        beeFramesPerHive: 12,
    },
    {
        date: '2024-02-15T00:00:00.000-08:00',
        pollinationPotential: 78.75,
        beeFramesPerHive: 12,
    },
    {
        date: '2024-02-16T00:00:00.000-08:00',
        pollinationPotential: 23.25,
        beeFramesPerHive: 12.4,
    },
    {
        date: '2024-02-17T00:00:00.000-08:00',
        pollinationPotential: 3.88,
        beeFramesPerHive: 12.4,
    },
    {
        date: '2024-02-18T00:00:00.000-08:00',
        pollinationPotential: 116.25,
        beeFramesPerHive: 12.4,
    },
    {
        date: '2024-02-19T00:00:00.000-08:00',
        pollinationPotential: 69.45,
        beeFramesPerHive: 12.7,
    },
    {
        date: '2024-02-20T00:00:00.000-08:00',
        pollinationPotential: 67.47,
        beeFramesPerHive: 12.7,
    },
    {
        date: '2024-02-21T00:00:00.000-08:00',
        pollinationPotential: 105.17,
        beeFramesPerHive: 12.7,
    },
    {
        date: '2024-02-22T00:00:00.000-08:00',
        pollinationPotential: 107.16,
        beeFramesPerHive: 12.7,
    },
    {
        date: '2024-02-23T00:00:00.000-08:00',
        pollinationPotential: 82.64,
        beeFramesPerHive: 12.9,
    },
    {
        date: '2024-02-24T00:00:00.000-08:00',
        pollinationPotential: 123.91,
        beeFramesPerHive: 13,
    },
    {
        date: '2024-02-25T00:00:00.000-08:00',
        pollinationPotential: 123.91,
        beeFramesPerHive: 13,
    },
    {
        date: '2024-02-26T00:00:00.000-08:00',
        pollinationPotential: 69.06,
        beeFramesPerHive: 13,
    },
    {
        date: '2024-02-27T00:00:00.000-08:00',
        pollinationPotential: 89.38,
        beeFramesPerHive: 13,
    },
    {
        date: '2024-02-28T00:00:00.000-08:00',
        pollinationPotential: 117.81,
        beeFramesPerHive: 13,
    },
    {
        date: '2024-02-29T00:00:00.000-08:00',
        pollinationPotential: 49.5,
        beeFramesPerHive: 13.2,
    },
    {
        date: '2024-03-01T00:00:00.000-08:00',
        pollinationPotential: 69.09,
        beeFramesPerHive: 13.4,
    },
    {
        date: '2024-03-02T00:00:00.000-08:00',
        pollinationPotential: 25.31,
        beeFramesPerHive: 13.5,
    },
    {
        date: '2024-03-03T00:00:00.000-08:00',
        pollinationPotential: 25.69,
        beeFramesPerHive: 13.7,
    },
    {
        date: '2024-03-04T00:00:00.000-08:00',
        pollinationPotential: 62.53,
        beeFramesPerHive: 13.8,
    },
    {
        date: '2024-03-05T00:00:00.000-08:00',
        pollinationPotential: 97.03,
        beeFramesPerHive: 13.8,
    },
    {
        date: '2024-03-06T00:00:00.000-08:00',
        pollinationPotential: 118.59,
        beeFramesPerHive: 13.8,
    },
    {
        date: '2024-03-07T00:00:00.000-08:00',
        pollinationPotential: 99.19,
        beeFramesPerHive: 13.8,
    },
    {
        date: '2024-03-08T00:00:00.000-08:00',
        pollinationPotential: 122.91,
        beeFramesPerHive: 13.8,
    },
    {
        date: '2024-03-09T00:00:00.000-08:00',
        pollinationPotential: 114.28,
        beeFramesPerHive: 13.8,
    },
    {
        date: '2024-03-10T00:00:00.000-08:00',
        pollinationPotential: 107.81,
        beeFramesPerHive: 13.8,
    },
];

export const dataVariation = [
    { date: '2024-01-29T00:00:00.000-08:00', pollinationPotential: 92.97, beeFramesPerHive: 11.9 },
    { date: '2024-01-30T00:00:00.000-08:00', pollinationPotential: 91.11, beeFramesPerHive: 11.9 },
    { date: '2024-01-31T00:00:00.000-08:00', pollinationPotential: 3.72, beeFramesPerHive: 11.9 },
    { date: '2024-02-01T00:00:00.000-08:00', pollinationPotential: 33.47, beeFramesPerHive: 11.9 },
    { date: '2024-02-02T00:00:00.000-08:00', pollinationPotential: 13.02, beeFramesPerHive: 11.9 },
    { date: '2024-02-03T00:00:00.000-08:00', pollinationPotential: 7.44, beeFramesPerHive: 11.9 },
    { date: '2024-02-04T00:00:00.000-08:00', pollinationPotential: 11.16, beeFramesPerHive: 11.9 },
    { date: '2024-02-05T00:00:00.000-08:00', pollinationPotential: 9.06, beeFramesPerHive: 11.6 },
    { date: '2024-02-06T00:00:00.000-08:00', pollinationPotential: 11.16, beeFramesPerHive: 11.9 },
    { date: '2024-02-07T00:00:00.000-08:00', pollinationPotential: 1.83, beeFramesPerHive: 11.7 },
    { date: '2024-02-08T00:00:00.000-08:00', pollinationPotential: 3.66, beeFramesPerHive: 11.7 },
    { date: '2024-02-09T00:00:00.000-08:00', pollinationPotential: 20.11, beeFramesPerHive: 11.7 },
    { date: '2024-02-10T00:00:00.000-08:00', pollinationPotential: 27.19, beeFramesPerHive: 11.6 },
    { date: '2024-02-11T00:00:00.000-08:00', pollinationPotential: 62.16, beeFramesPerHive: 11.7 },
    { date: '2024-02-12T00:00:00.000-08:00', pollinationPotential: 56.67, beeFramesPerHive: 11.7 },
    { date: '2024-02-13T00:00:00.000-08:00', pollinationPotential: 53.02, beeFramesPerHive: 11.7 },
    { date: '2024-02-14T00:00:00.000-08:00', pollinationPotential: 34.73, beeFramesPerHive: 11.7 },
    { date: '2024-02-15T00:00:00.000-08:00', pollinationPotential: 78.75, beeFramesPerHive: 12 },
    { date: '2024-02-16T00:00:00.000-08:00', pollinationPotential: 22.5, beeFramesPerHive: 12 },
    { date: '2024-02-17T00:00:00.000-08:00', pollinationPotential: 3.75, beeFramesPerHive: 12 },
    { date: '2024-02-18T00:00:00.000-08:00', pollinationPotential: 116.25, beeFramesPerHive: 12.4 },
    { date: '2024-02-19T00:00:00.000-08:00', pollinationPotential: 67.81, beeFramesPerHive: 12.4 },
    { date: '2024-02-20T00:00:00.000-08:00', pollinationPotential: 65.88, beeFramesPerHive: 12.4 },
    { date: '2024-02-21T00:00:00.000-08:00', pollinationPotential: 102.69, beeFramesPerHive: 12.4 },
    { date: '2024-02-22T00:00:00.000-08:00', pollinationPotential: 107.16, beeFramesPerHive: 12.7 },
    { date: '2024-02-23T00:00:00.000-08:00', pollinationPotential: 82.64, beeFramesPerHive: 12.9 },
    { date: '2024-02-24T00:00:00.000-08:00', pollinationPotential: 122.95, beeFramesPerHive: 12.9 },
    { date: '2024-02-25T00:00:00.000-08:00', pollinationPotential: 122.95, beeFramesPerHive: 12.9 },
    { date: '2024-02-26T00:00:00.000-08:00', pollinationPotential: 69.06, beeFramesPerHive: 13 },
    { date: '2024-02-27T00:00:00.000-08:00', pollinationPotential: 89.38, beeFramesPerHive: 13 },
    { date: '2024-02-28T00:00:00.000-08:00', pollinationPotential: 117.81, beeFramesPerHive: 13 },
    { date: '2024-02-29T00:00:00.000-08:00', pollinationPotential: 49.5, beeFramesPerHive: 13.2 },
    { date: '2024-03-01T00:00:00.000-08:00', pollinationPotential: 68.06, beeFramesPerHive: 13.2 },
    { date: '2024-03-02T00:00:00.000-08:00', pollinationPotential: 24.75, beeFramesPerHive: 13.2 },
    { date: '2024-03-03T00:00:00.000-08:00', pollinationPotential: 25.31, beeFramesPerHive: 13.5 },
    { date: '2024-03-04T00:00:00.000-08:00', pollinationPotential: 62.53, beeFramesPerHive: 13.8 },
    { date: '2024-03-05T00:00:00.000-08:00', pollinationPotential: 97.03, beeFramesPerHive: 13.8 },
    { date: '2024-03-06T00:00:00.000-08:00', pollinationPotential: 116.02, beeFramesPerHive: 13.5 },
    { date: '2024-03-07T00:00:00.000-08:00', pollinationPotential: 97.03, beeFramesPerHive: 13.5 },
    { date: '2024-03-08T00:00:00.000-08:00', pollinationPotential: 120.23, beeFramesPerHive: 13.5 },
    { date: '2024-03-09T00:00:00.000-08:00', pollinationPotential: 112.63, beeFramesPerHive: 13.6 },
    { date: '2024-03-10T00:00:00.000-08:00', pollinationPotential: 106.25, beeFramesPerHive: 13.6 },
];

const ranchVariation = ['86cc13bc-d9eb-4181-9d16-d93a36e0899e', '966fb6ac-cd5c-4a85-9b2b-a03fad63c566'];

export const getRanchData = ranchId => (ranchVariation.includes(ranchId) ? dataVariation : data);

export const getBeeAverage = ranchId => (ranchVariation.includes(ranchId) ? 13.6 : 13.8);

export const getHiveCorrectionPercent = ranchId => (ranchVariation.includes(ranchId) ? 8 : 4.8);

export const categories = [
    { hives: 0, percent: 84.7, name: '>10 Frames' },
    { hives: 0, percent: 7.3, name: '8-10 Frames' },
    { hives: 0, percent: 6, name: '6-8 Frames' },
    { hives: 0, percent: 2.2, name: '<6 Frames' },
];

export const categoriesVariations = [
    { hives: 0, percent: 78, name: '>10 Frames' },
    { hives: 0, percent: 14.5, name: '8-10 Frames' },
    { hives: 0, percent: 6, name: '6-8 Frames' },
    { hives: 0, percent: 1.5, name: '<6 Frames' },
];

export const getCategories = ranchId => (ranchVariation.includes(ranchId) ? categoriesVariations : categories);

export const deploymentData = {
    '8404535d-7a23-4670-a7fa-23dceb2d6d2e': {
        name: 'GLI - Berenda s/o Ave 24',
        bhomes: 24,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    'bc2bcb48-51ae-48e7-a222-75388fd6912d': {
        name: 'Gallo- Santa Rita Farms',
        bhomes: 18,
        deploymentDate: '2024-02-13T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    '36d783d8-76ae-421c-abd2-33de87e25273': {
        name: 'Nuveen/Westchester',
        bhomes: 28,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    '0405cd42-f382-47cc-adf4-0cbfc82c6b63': {
        name: 'Specialty Crop - Harris - Carroz',
        bhomes: 12,
        deploymentDate: '2024-02-14T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    '96202626-31bd-4e67-9080-120ce926cdb0': {
        name: 'Woolf – Myers Ranch BNR',
        bhomes: 14,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    '0e39d4c4-88df-45b7-9811-0e20b3784b9e': {
        name: 'DCB Farming - Montague Lease (El Nido)',
        bhomes: 4,
        deploymentDate: '2024-02-14T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    '8e1f7db5-f68e-4365-be64-1cd839048b46': {
        name: 'Ramu - 22802 Ave 11',
        bhomes: 4,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    '55bc1493-37f4-41ee-8059-bfe9e47db543': {
        name: 'A Bar',
        bhomes: 5,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    '341ef5f0-84d6-4d9d-b2fe-0356298d5d93': {
        name: 'Cantua Creek - Lucky Estates',
        bhomes: 12,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    '86cc13bc-d9eb-4181-9d16-d93a36e0899e': {
        name: '670 Ranch - BH Only',
        bhomes: 3,
        deploymentDate: '2024-02-14T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    '2e39e231-b78b-4110-b9f2-bb1fafe98fa7': {
        name: 'Mission Ranch - BH only',
        bhomes: 8,
        deploymentDate: '2024-02-14T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    '5afa30c5-df3a-49ee-b0e8-8cc0d66a4815': {
        name: 'Ranch 122, 124, 125, 126, & 129 - BH only',
        bhomes: 3,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    '5e4242bb-f4bb-4f99-af5d-4b08b301c920': {
        name: 'JJS Ranch',
        bhomes: 7,
        deploymentDate: '2024-02-23T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    '5870ca7c-b586-494e-8c30-b5a9d4d163ec': {
        name: 'Parrish Farms – NW Av 8 & Hwy 145',
        bhomes: 6,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    '88442f57-fa7a-4464-a946-48fc34034cea': {
        name: 'McKinney Home/Silver & Sordi – SW Rd 23 & Av 14',
        bhomes: 6,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    '2b499dc8-f1c4-410f-a170-a22d3ec52bf5': {
        name: 'Foletta Farms - Taylor Road',
        bhomes: 13,
        deploymentDate: '2024-02-21T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    '4b9f1eeb-b851-4838-8a03-c48046a1905b': {
        name: 'Central Chowchilla',
        bhomes: 6,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    'ffd43167-2374-4535-a2e1-18e2a36127cb': {
        name: 'Lest – Pope Creek - NW Ave 12 and Road 16',
        bhomes: 6,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    '966fb6ac-cd5c-4a85-9b2b-a03fad63c566': {
        name: 'Pescadero',
        bhomes: 3,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    'f23fc4d5-032d-49c0-b05e-bb5ea27ee032': {
        name: 'Neru - Moira',
        bhomes: 21,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    'e3753933-7cc3-49a5-9627-4ac6c1bb51c6': {
        name: 'NSH Farms (Jasleen)',
        bhomes: 1,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    'da142fd5-b227-4714-87b7-27786c862a75': {
        name: 'Pimentel Farms Westley',
        bhomes: 22,
        deploymentDate: '2024-02-24T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    'dac5cae5-321f-4395-9c56-36bdd76f3dab': {
        name: 'Gino',
        bhomes: 16,
        deploymentDate: '2024-02-21T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    'b485f582-85fb-4bbf-9d48-b67b7b46ef58': {
        name: 'Van Groningen & Sons',
        bhomes: 16,
        deploymentDate: '2024-02-21T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    'a8010d28-2285-40fa-81f2-74cae3b04e19': {
        name: 'LO2',
        bhomes: 2,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    '2d522bf2-4305-4f87-8bd2-40624fabc1c0': {
        name: 'South - Howard Ranch',
        bhomes: 16,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    'c9644ff4-808b-41ae-b849-8e4cf32c478c': {
        name: 'Los Banos S River',
        bhomes: 6,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    '0098e99d-7f30-4d0d-904e-369375d4a095': {
        name: 'Los Banos S River',
        bhomes: 6,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
    'dd750e28-e3c8-48ab-9157-aae486deaa33': {
        name: 'Los Banos S River',
        bhomes: 1,
        deploymentDate: '2024-02-20T00:00:00.000-08:00',
        removalDate: '2024-03-10T00:00:00.000-08:00',
    },
};

export const getHardcodedCorrection = ({ totalBhomesHives, hiveCorrectionPercent }) => {
    const correctedHivesAmount = Math.round((hiveCorrectionPercent * totalBhomesHives) / 100);
    const percent = correctedHivesAmount && hiveCorrectionPercent;

    return `${correctedHivesAmount} (${percent}%)`;
};
