import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';
import isSignInOrUpView from 'utils/isSignInOrUpView';
import { ROUTE_PATHS } from 'router/constants';
import logo from 'assets/beewise-logo.svg';
import Menu from './Menu';

import './AdminAppHeader.scss';

const NavBar = () => (
    <div className="nav-list">
        <NavLink to={ROUTE_PATHS.DASHBOARD} className={cx({ active: false })}>
            Home
        </NavLink>
        <NavLink to={ROUTE_PATHS.CONTACTS}>Contacts</NavLink>
    </div>
);

const Logo = () => (
    <div className="logo-container">
        <NavLink to={ROUTE_PATHS.DASHBOARD}>
            <img className="logo" src={logo} alt="Beewise logo" />
        </NavLink>
        <div className="app-env beta">Beta</div>
    </div>
);

const BrandName = () => <div className="nav-list">Beewise Pollination Management</div>;

const AdminAppHeader = ({ isAdminPage }) => {
    const location = useLocation();
    const isSignIn = isSignInOrUpView(location);
    const isReportPage = location.pathname.includes(ROUTE_PATHS.REPORT);

    return (
        <header className="main-header">
            <Logo />
            {!isSignIn && (
                <>
                    {isAdminPage && !isReportPage ? <NavBar /> : <BrandName />}
                    <Menu />
                </>
            )}
        </header>
    );
};

AdminAppHeader.propTypes = {
    isAdminPage: PropTypes.bool,
};

export default AdminAppHeader;
