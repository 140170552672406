import { useState } from 'react';
import useDrawingMode from './useDrawingMode';
import useMarkerMode from './useMarkerMode';
import useCreateInitialFigures from './useCreateInitialFigures';

const useMap = ({
    addPolygon,
    addAccessRoad,
    removeAccessRoad,
    removePolygon,
    updatePolygon,
    isToolbarEnabled,
    createLocation,
    removeLocation,
    updateLocation,
    form,
    pins,
    createPin,
    removePin,
    updatePin,
}) => {
    const [mode, setMode] = useState(null);

    const { handleModeChange, onGoogleApiLoaded, map, polygonsRef, accessRoadRef } = useDrawingMode({
        addPolygon,
        addAccessRoad,
        removeAccessRoad,
        mode,
        isToolbarEnabled,
        removePolygon,
        updatePolygon,
        setMode,
    });
    useCreateInitialFigures({ map, polygonsRef, accessRoadRef });
    const { handleMarkerModeChange, handleEditLocationMarker, isDraggingRef, handleEditMarker } = useMarkerMode({
        map,
        mode,
        polygonsRef,
        createLocation,
        removeLocation,
        updateLocation,
        form,
        pins,
        createPin,
        removePin,
        updatePin,
    });
    const onModeChange = mode => {
        handleModeChange(mode);
        handleMarkerModeChange(mode);
        setMode(mode);
    };

    return {
        mode,
        onModeChange,
        map,
        polygonsRef,
        isDraggingRef,
        handleEditLocationMarker,
        onGoogleApiLoaded,
        handleEditMarker,
    };
};

export default useMap;
