import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@beewise/tooltip';
import '../ReportContainer.scss';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { calculateSavedBees } from 'utils/calculateSavedBees';
import { formatNumberWithUnits } from 'utils/formatNumberWithUnits';

const NumberOfSavedBees = ({ startDate, ranch: { name }, bhomesAmount }) => {
    const savedBees = calculateSavedBees(bhomesAmount);
    const formattedNumberOfSavedBees = formatNumberWithUnits(savedBees);

    const startYear = new Date(startDate).getFullYear();
    const currentYear = new Date().getFullYear();

    if (savedBees < 0) {
        return;
    }

    return (
        <div className="report-container rounded-container">
            <h2 className="title">{formattedNumberOfSavedBees}</h2>
            <div className="info-block">
                Bees Saved by {name} in {startYear === currentYear ? startYear : `${startYear} - ${currentYear}`}
                <Tooltip content="BeeHomes reduce annual colony losses by more than 70%" position="right">
                    <FontAwesomeIcon icon={faCircleExclamation} className="tooltip-icon" />
                </Tooltip>
            </div>
        </div>
    );
};

NumberOfSavedBees.propTypes = {
    startDate: PropTypes.string.isRequired,
    ranch: PropTypes.shape({
        name: PropTypes.string,
    }),
    bhomesAmount: PropTypes.number.isRequired,
};

export default memo(NumberOfSavedBees);
