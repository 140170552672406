import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Tooltip from '@beewise/tooltip';
import { getColorStyles } from '../utils';
import './RanchMarkerV2.scss';

const getIcon = ({ marker }) => {
    const colorStyles = getColorStyles({
        placedHivesAmount: marker.totalPlacedHives,
        plannedHivesAmount: marker.totalPlannedHives,
    });

    return <div className={cx('ranch-icon', colorStyles)} />;
};

const getTooltipInfo = ({ marker }) => {
    if (marker.totalPlacedHives === marker.totalPlannedHives) {
        return `${marker.totalPlacedHives} placed`;
    } else if (!marker.totalPlacedHives) {
        return `${marker.totalPlannedHives} planned`;
    }

    return `${marker.totalPlacedHives}/${marker.totalPlannedHives} placed`;
};

const RanchMarkerV2 = ({ handleMarkerClick, ...marker }) => {
    const icon = getIcon({ marker });
    const tooltipInfo = getTooltipInfo({ marker });

    const tooltipData = (
        <div className="tooltip-marker">
            <p className="tooltip-marker-name">{marker.name}</p>
            <p className="tooltip-marker-info">{tooltipInfo}</p>
        </div>
    );

    const handleRanchMarkerClick = () => handleMarkerClick({ ranchId: marker.id });

    return (
        <div onClick={handleRanchMarkerClick} role="presentation" id={marker?.id}>
            <Tooltip text={tooltipData} position="top center" offset={[0, 22, 0, 0]}>
                {icon}
            </Tooltip>
        </div>
    );
};

RanchMarkerV2.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    totalPlacedHives: PropTypes.number,
    totalPlannedHives: PropTypes.number,
    handleMarkerClick: PropTypes.func,
};

export default RanchMarkerV2;
