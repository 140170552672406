import React, { useCallback, useRef, createContext, useMemo, useContext } from 'react';
import { difference, keyBy } from 'lodash-es';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setHoveredRanchId } from 'components/views/BrokerView/actions';
import { createPolygon } from 'components/reusables/Map/components/utils';

const MapContext = createContext();

const POLYGON_COLORS = {
    HOVERED: 'rgba(221, 192, 128, 0.4)',
    SELECTED: 'rgba(221, 192, 128, 0.8)',
    DEFAULT: 'transparent',
};

export const getChangedEntityIds = (currentEntities, newEntities) => {
    const currentEntitiesIds = currentEntities.filter(v => v?.visible)?.map(it => it?.id);
    const newEntitiesIds = newEntities?.map(it => it?.id);
    const entitiesToRemove = difference(currentEntitiesIds, newEntitiesIds);
    const entitiesToAdd = difference(newEntitiesIds, currentEntitiesIds);
    return { idsRemove: entitiesToRemove, idsAdd: entitiesToAdd };
};

// TODO create separate provider for grower view as it does not need to handle hover and selection and map interactions
export const MapProvider = ({ children, isBrokerView = false }) => {
    const dispatch = useDispatch();
    const selectedRanchRef = useRef(null);
    const mapRef = useRef({ map: null, polygons: [] });

    const findPolygonById = useCallback(ranchId => mapRef.current.polygons.find(polygon => polygon.id === ranchId), []);

    const setSelectedRanchRef = useCallback(ranchId => {
        selectedRanchRef.current = ranchId;
    }, []);

    const hoverStrategies = useMemo(
        () => ({
            byPolygon: polygon => ({
                polygon,
                ranchId: polygon.id,
            }),
            byRanchId: ranchId => ({
                polygon: findPolygonById(ranchId),
                ranchId,
            }),
        }),
        [findPolygonById]
    );

    const handleRanchHover = useCallback(
        ({ ranchId, polygon }) => {
            const target = polygon ? hoverStrategies.byPolygon(polygon) : hoverStrategies.byRanchId(ranchId);
            if (target.ranchId === selectedRanchRef.current) {
                return;
            }
            dispatch(setHoveredRanchId(target.ranchId));
            target?.polygon?.setOptions({ fillColor: POLYGON_COLORS.HOVERED, cursor: 'pointer' });
        },
        [dispatch, hoverStrategies]
    );

    const handleRanchHoverEnd = useCallback(
        ({ ranchId, polygon }) => {
            const target = polygon ? hoverStrategies.byPolygon(polygon) : hoverStrategies.byRanchId(ranchId);
            if (target.ranchId === selectedRanchRef.current) {
                return;
            }
            target?.polygon?.setOptions({ fillColor: POLYGON_COLORS.DEFAULT, cursor: null });
            dispatch(setHoveredRanchId(null));
        },
        [dispatch, hoverStrategies]
    );

    const handleTogglePolygon = useCallback(
        ({ ranchId: id, isSelected }) => {
            if (!isBrokerView) {
                return;
            }
            const polygon = mapRef.current.polygons.find(polygon => polygon.id === id);
            if (polygon) {
                polygon.setOptions({ fillColor: isSelected ? POLYGON_COLORS.SELECTED : POLYGON_COLORS.DEFAULT });
                polygon.isSelected = isSelected;
            }
        },
        [isBrokerView]
    );

    const handleSelectPolygon = useCallback(
        ({ ranchId: id }) => {
            const isSameRanch = id === selectedRanchRef.current;
            if (!isBrokerView || isSameRanch) {
                return;
            }
            selectedRanchRef.current = id;
            const prevSelectedPolygon = mapRef.current.polygons.find(polygon => polygon.isSelected);
            if (prevSelectedPolygon) {
                prevSelectedPolygon.setOptions({ fillColor: POLYGON_COLORS.DEFAULT });
                prevSelectedPolygon.isSelected = false;
            }
            handleTogglePolygon({ ranchId: id, isSelected: true });
        },
        [isBrokerView, handleTogglePolygon]
    );

    const createAndAddShape = useCallback(
        ({ ranch, handleSelectCurrentItem, isPolygonFilled = true }) => {
            const isSelected = ranch.id === selectedRanchRef.current;
            const color = isSelected && isPolygonFilled ? POLYGON_COLORS.SELECTED : POLYGON_COLORS.DEFAULT;

            const polygon = createPolygon({
                map: mapRef.current.map,
                polygon: ranch.polygons,
                fillColor: color,
            });
            polygon.id = ranch.id;
            polygon.addListener('click', () => {
                handleSelectCurrentItem({ ranchId: ranch.id });
            });
            if (isBrokerView) {
                polygon.addListener('mouseover', () => {
                    handleRanchHover({ polygon });
                });
                polygon.addListener('mouseout', () => {
                    handleRanchHoverEnd({ polygon });
                });
            }
            return polygon;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, isBrokerView, handleRanchHover, handleRanchHoverEnd]
    );

    const createShapesForItems = useCallback(
        ({ ranches, handleSelectCurrentItem, isPolygonFilled }) => {
            const { idsRemove, idsAdd } = getChangedEntityIds(mapRef.current.polygons, ranches);
            const ranchesByIds = keyBy(ranches, 'id');

            const polygonsMap = new Map(mapRef.current.polygons.map(polygon => [polygon.id, polygon]));
            if (idsAdd.length) {
                idsAdd.forEach(id => {
                    if (!polygonsMap.has(id)) {
                        const polygon = createAndAddShape({
                            ranch: ranchesByIds[id],
                            handleSelectCurrentItem,
                            isPolygonFilled,
                        });
                        polygon.id = id;
                        mapRef.current.polygons.push(polygon);
                    } else {
                        polygonsMap.get(id).setVisible(true);
                    }
                });
            }

            if (idsRemove.length) {
                idsRemove.forEach(id => {
                    const polygon = polygonsMap.get(id);
                    if (polygon) {
                        polygon.setVisible(false);
                    }
                });
            }
        },
        [createAndAddShape]
    );

    const data = useMemo(
        () => ({
            mapRef,
            handleRanchHover,
            handleRanchHoverEnd,
            handleSelectPolygon,
            createAndAddShape,
            createShapesForItems,
            setSelectedRanchRef,
            handleTogglePolygon,
        }),
        [
            mapRef,
            handleRanchHover,
            handleRanchHoverEnd,
            handleSelectPolygon,
            createAndAddShape,
            createShapesForItems,
            setSelectedRanchRef,
            handleTogglePolygon,
        ]
    );

    return <MapContext.Provider value={data}>{children}</MapContext.Provider>;
};

MapProvider.propTypes = {
    children: PropTypes.node,
    isBrokerView: PropTypes.bool,
};

export const useMapContext = () => useContext(MapContext);
