import { chunk } from 'lodash';
import constants from 'appConstants';

const {
    REPORTS: { CORRECTION_FACTOR },
} = constants;

const EXTRA_FRAMES_FACTOR = 2;

const DEFAULT_RANCH_SETTINGS = {
    correctionPercentFactor: [CORRECTION_FACTOR],
    extraFramesFactor: [EXTRA_FRAMES_FACTOR],
};

const splitIntoNChunks = (array, numChunks) => {
    if (!array?.length) return [];
    if (numChunks <= 0) return [array];

    const chunkSize = Math.ceil(array.length / numChunks);
    return chunk(array, chunkSize);
};

const applyFactorsToChunks = (chunks, factors, field, transformer) =>
    chunks
        .map((chunk, index) => {
            const factor = factors[index];
            return chunk.map(item => ({
                ...item,
                [field]: transformer(item[field], factor),
            }));
        })
        .flat();

export const prepareFactoredData =
    ({ field, shouldAddFrames = false }) =>
    (data, ranchSettings = {}) => {
        if (!data?.length) return [];

        const { correctionPercentFactor, extraFramesFactor } = {
            ...DEFAULT_RANCH_SETTINGS,
            ...ranchSettings,
        };

        // Apply correction factors
        const chunks = splitIntoNChunks(data, correctionPercentFactor.length);
        let factoredData = applyFactorsToChunks(
            chunks,
            correctionPercentFactor,
            field,
            (value, factor) => value * factor
        );

        // Apply extra frames if needed
        if (shouldAddFrames) {
            const extraFramesChunks = splitIntoNChunks(factoredData, extraFramesFactor.length);
            factoredData = applyFactorsToChunks(
                extraFramesChunks,
                extraFramesFactor,
                field,
                (value, factor) => value + factor
            );
        }

        return factoredData;
    };

export const prepareFactoredDataPerHive = (framesPerBhome = {}, ranchSettings = {}) => {
    const { correctionPercentFactor, extraFramesFactor } = {
        ...DEFAULT_RANCH_SETTINGS,
        ...ranchSettings,
    };
    const lastCorrectionFactorPercent = correctionPercentFactor[correctionPercentFactor.length - 1];
    const lastExtraFramesFactor = extraFramesFactor[extraFramesFactor.length - 1];

    if (!Object.keys(framesPerBhome)?.length) {
        return [];
    }
    const framesPerHives = Object.values(framesPerBhome).flat();

    return framesPerHives.map(item => item * lastCorrectionFactorPercent + lastExtraFramesFactor);
};
