import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SignInForm } from '@beewise/auth-form';
import analytics from 'utils/analytics';
import { AUTH_FORM_TITLE } from 'components/constants';
import { fetchForgotPassword, fetchSignIn, setForgotPasswordError } from './actions/sign.in.actions';

const SignIn = () => {
    const dispatch = useDispatch();
    const { forgotPasswordError, forgotPasswordSent, error } = useSelector(state => state.signin);

    const submitSignIn = data => {
        const { email, password } = data;

        if (!email || !password) {
            return;
        }

        dispatch(fetchSignIn(email, password));

        analytics.fireGeneralEvent({
            action: 'Sign In',
        });
    };

    const toggleForgotPassword = email => {
        dispatch(setForgotPasswordError(null));
        dispatch(fetchForgotPassword(email));
    };

    return (
        <SignInForm
            onSubmit={submitSignIn}
            onForgotPassword={toggleForgotPassword}
            forgotPasswordSent={forgotPasswordSent}
            error={error}
            forgotPasswordError={forgotPasswordError}
            title={AUTH_FORM_TITLE}
        />
    );
};

export default SignIn;
