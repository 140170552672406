import React from 'react';
import PropTypes from 'prop-types';
import AddRanch from './AddRanch';
import RanchesList from './RanchesList';
import RanchesPerformanceBlock from './RanchesPerformanceBlock';
import RanchesFiltering from './RanchesFiltering';
import SearchRanches from './SearchRanches';
import './RanchesBlock.scss';

const RanchesBlock = ({ ranches, filteredRanches }) => (
    <div className="ranches-container">
        <RanchesFiltering />
        <RanchesPerformanceBlock ranches={ranches} />
        <AddRanch ranches={filteredRanches} />
        <SearchRanches />
        <RanchesList filteredRanches={filteredRanches} ranches={ranches} />
    </div>
);

export default RanchesBlock;

RanchesBlock.propTypes = {
    ranches: PropTypes.arrayOf(PropTypes.shape()),
    filteredRanches: PropTypes.arrayOf(PropTypes.shape()),
};
