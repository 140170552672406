import { createAsyncAction } from '@beewise/utils';

export const FETCH_HEAT_MAP = createAsyncAction('FETCH_HEAT_MAP');

export const fetchHeatMap = ({ ranchId, resolver, rejector }) => ({
    type: 'FETCH',
    params: {
        type: FETCH_HEAT_MAP.default,
        url: `/ranches/reports/${ranchId}/heatmap`,
        resolver,
        rejector,
    },
});
