import { useRef, useEffect, useState, useCallback } from 'react';
import { createDrawingManager, COMPLETED_POLYGON_OPTIONS } from 'utils/drawingManager';
import { getPolygonArea, getPolygonCountry } from 'utils';
import { TOOLBAR_MODES } from '../utils';
import customCursor from './customCursor';

const toggleDraggableFeature = (polygon, isDraggable) => {
    polygon.setDraggable(isDraggable);
    polygon.setEditable(isDraggable);
};

const makePolygonEditable = polygon => {
    toggleDraggableFeature(polygon, true);
    window.google.maps.event.clearListeners(polygon, 'click');
    polygon.setOptions({
        draggableCursor: 'default',
        cursor: 'default',
    });
};

export const getPolygonCoords = polygon =>
    polygon
        .getPath()
        .getArray()
        .map(item => ({
            lat: item.lat(),
            lng: item.lng(),
        }));

export const getLineCoords = polyline =>
    polyline
        .getPath()
        .getArray()
        .reduce((acc, { lat, lng }) => [...acc, { lat: lat(), lng: lng() }], []);

const clearAllListeners = ({ drawingManager, polygons, map, accessRoads }) => {
    if (!drawingManager) {
        return;
    }
    window.google.maps.event.clearInstanceListeners(drawingManager);
    map && window.google.maps.event.clearInstanceListeners(map);
    polygons.forEach(window.google.maps.event.clearInstanceListeners);
    accessRoads.forEach(window.google.maps.event.clearInstanceListeners);
};

const useDrawingMode = ({
    addPolygon,
    addAccessRoad,
    removeAccessRoad,
    removePolygon,
    updatePolygon,
    isToolbarEnabled,
    setMode,
}) => {
    const mapRef = useRef(null);
    const [map, setMap] = useState(null);
    const drawingManagerRef = useRef(null);
    const polygonsRef = useRef([]);
    const accessRoadRef = useRef([]);

    const createNewPolygon = polygon => {
        polygon.setOptions(COMPLETED_POLYGON_OPTIONS);
        const country = getPolygonCountry(polygon);
        const area = getPolygonArea(polygon, country);
        const coords = getPolygonCoords(polygon);
        polygon.area = area.toFixed(2);
        if (area > 1) {
            toggleDraggableFeature(polygon, true);
            polygon.id = JSON.stringify(coords);
            polygonsRef.current.push(polygon);
            addPolygon(coords);
            return;
        }
        toggleDraggableFeature(polygon, false);
        polygon.setMap(null);
    };

    const handleRemovePolygon = polygon => () => {
        // remove polygon from map
        polygon.setMap(null);
        // remove polygon from form data
        removePolygon(polygon.id);
        // remove polygon from polygonsRef
        polygonsRef.current = polygonsRef.current.filter(item => item !== polygon);
    };

    const createNewAccessRoad = polyline => {
        const coords = getLineCoords(polyline);
        polyline.id = JSON.stringify(coords);
        accessRoadRef.current.push(polyline);
        addAccessRoad(coords);
    };

    const handleEnableDrawingMode = ({ type, callback, event }) => {
        drawingManagerRef.current.setDrawingMode(type);
        window.google.maps.event.addListener(drawingManagerRef.current, event, callback);
        polygonsRef.current.forEach(makePolygonEditable);
    };

    const handleDisableDrawingMode = () => {
        drawingManagerRef.current?.setDrawingMode(null);
        polygonsRef.current?.forEach(polygon => {
            toggleDraggableFeature(polygon, false);
        });
    };

    const handleModifyPolygon = polygon => () => {
        const coords = getPolygonCoords(polygon);
        updatePolygon({ id: polygon.id, polygon: coords });
        polygon.id = JSON.stringify(coords);
    };

    const handleAddPolygonDragListeners = polygon => {
        polygon.getPaths().forEach(path => {
            window.google.maps.event.addListener(path, 'set_at', handleModifyPolygon(polygon));
            window.google.maps.event.addListener(path, 'insert_at', handleModifyPolygon(polygon));
        });
    };

    const handleEnableEditMode = () => {
        drawingManagerRef.current?.setDrawingMode(null);
        polygonsRef.current.forEach(makePolygonEditable);
        polygonsRef.current.forEach(handleAddPolygonDragListeners);
    };

    const handleRemoveAccessRoad = polyline => () => {
        polyline.setMap(null);
        removeAccessRoad(polyline.id);

        accessRoadRef.current = accessRoadRef.current.filter(item => item !== polyline);
    };

    const addEventListeners = (item, handleRemove) => {
        window.google.maps.event.addListener(item, 'mousedown', handleRemove(item));
        window.google.maps.event.addListener(item, 'mouseover', () => {
            item.setOptions({
                cursor: `url(${customCursor}), auto`,
            });
        });
        window.google.maps.event.addListener(item, 'mouseout', () => {
            item.setOptions({
                cursor: 'default',
            });
            map.setOptions({ draggableCursor: 'default', cursor: 'default' });
        });
    };

    const handleEnableDeleteMode = () => {
        handleDisableDrawingMode();

        polygonsRef.current.forEach(polygon => {
            addEventListeners(polygon, handleRemovePolygon);
        });

        accessRoadRef.current.forEach(polyline => {
            addEventListeners(polyline, handleRemoveAccessRoad);
        });
    };

    const handleModeChange = mode => {
        clearAllListeners({
            drawingManager: drawingManagerRef.current,
            polygons: polygonsRef.current,
            map,
            accessRoads: accessRoadRef.current,
        });

        if (mode === TOOLBAR_MODES.DRAW_ROAD.title && drawingManagerRef.current?.drawingMode === 'polyline') {
            handleDisableDrawingMode();
            return;
        }

        switch (mode) {
            case TOOLBAR_MODES.DRAW_POLYGON.title:
                handleEnableDrawingMode({
                    type: 'polygon',
                    event: 'polygoncomplete',
                    callback: createNewPolygon,
                });
                break;
            case TOOLBAR_MODES.DRAW_ROAD.title:
                handleEnableDrawingMode({
                    type: 'polyline',
                    event: 'polylinecomplete',
                    callback: createNewAccessRoad,
                });
                break;
            case TOOLBAR_MODES.EDIT_POLYGON.title:
                handleEnableEditMode();
                break;
            case TOOLBAR_MODES.CLEAR_PATH.title:
                handleEnableDeleteMode();
                break;
            default:
                handleDisableDrawingMode();
                break;
        }
    };

    const handleKeyDown = useCallback(event => {
        if (event.key === 'Enter') {
            handleDisableDrawingMode();
        }
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(
        () => () => {
            setMode(null);
            handleDisableDrawingMode();
            clearAllListeners({
                drawingManager: drawingManagerRef.current,
                polygons: polygonsRef.current,
                map,
                accessRoads: accessRoadRef.current,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isToolbarEnabled, map]
    );
    const onGoogleApiLoaded = ({ map }) => {
        if (!drawingManagerRef.current) {
            createDrawingManager(map, drawingManagerRef);
        }
        setMap(map);
    };

    return { mapRef, map, handleModeChange, onGoogleApiLoaded, polygonsRef, accessRoadRef };
};

export default useDrawingMode;
