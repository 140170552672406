import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import localStorage from 'local-storage-fallback';
import { faCircleQuestion, faClose } from '@fortawesome/pro-regular-svg-icons';
import MapInfoWrapper from '../../MapInfo/MapInfoWrapper';
import './HeatMapInfoButton.scss';

const LOCAL_STORAGE_KEY = 'isHeatMapInfoHidden';

const getIsHeatmapInfoHidden = () => localStorage.getItem(LOCAL_STORAGE_KEY);

const InfoIcon = ({ onClick }) => (
    <MapInfoWrapper className="heat-map-info-button">
        <span onClick={onClick} role="presentation">
            <FontAwesomeIcon icon={faCircleQuestion} className="heat-map-info-button" size={16} />
        </span>
    </MapInfoWrapper>
);

InfoIcon.propTypes = {
    onClick: PropTypes.func.isRequired,
};

const InfoText = ({ onClick }) => (
    <MapInfoWrapper className="heat-map-info">
        <span>
            <FontAwesomeIcon icon={faClose} onClick={onClick} />
            This heatmap shows bee visitation rates over time, with each week aggregating previous data.
        </span>
    </MapInfoWrapper>
);

InfoText.propTypes = {
    onClick: PropTypes.func.isRequired,
};

const HeatMapInfoButton = () => {
    const isHeatmapInfoHidden = getIsHeatmapInfoHidden();
    const [isInfoShown, setIsInfoShown] = useState(!isHeatmapInfoHidden);

    const toggleInfoShown = () => {
        setIsInfoShown(!isInfoShown);
        if (!isHeatmapInfoHidden) {
            localStorage.setItem(LOCAL_STORAGE_KEY, 1);
        }
    };

    const Component = isInfoShown ? InfoText : InfoIcon;
    return <Component onClick={toggleInfoShown} />;
};

export default HeatMapInfoButton;
